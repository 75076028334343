import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import React from "react";

type Props = {};

export const OverallStatus = ({ status }) => {
  return (
    <>
      <div>General status of the guarded systems.</div>
      <Card className="bg-Orange/40 border-[0.25px] shadow-md mx-2 sm:mx-0 sm:mr-14">
        <CardHeader>
          <CardTitle>Status</CardTitle>
          <CardDescription>{status}</CardDescription>
        </CardHeader>
        <CardContent></CardContent>
      </Card>
    </>
  );
};
