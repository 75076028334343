import { HTMLAttributes, useState } from "react";
import { auth, useAuthStore } from "../../auth-store";
import { ToastAction } from "@radix-ui/react-toast";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

interface SetPasswordFormProps extends HTMLAttributes<HTMLDivElement> {
  code: string;
}

export const RecoverEmailForm = ({ code }: SetPasswordFormProps) => {
  const { checkActionCode, applyActionCode } = useAuthStore((state) => state);
  const [recovery, setRecovery] = useState<boolean>(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSubmit = async (e) => {
    let restoredEmail = null;
    checkActionCode(auth, code)
      .then((info) => {
        restoredEmail = info["data"]["email"];
        // Revert to the old email.
        return applyActionCode(auth, code);
      })
      .then(() => {
        console.log("Email recovered");
        setRecovery(true);
      })
      .catch((error) => {
        console.error("Unable to recover email:", error);
        toast({
          variant: "destructive",
          title: "Error!",
          description: "It is not possible to recover your email address at this time.",
          className: "flex flex-inline py-3",
          action: (
            <ToastAction
              className="flex px-3 my-3 border-2 rounded-lg text-sm text-center"
              altText="Retry"
              onClick={() => {
                navigate("/auth/update-mail");
              }}
            >
              Retry
            </ToastAction>
          ),
        });
      });
  };

  return (
    <div className="flex flex-col justify-center font-sans">
      {recovery ? (
        <div className="font-semibold text-base text-green-600 text-sm text-muted-foreground text-center pb-6 pt-3">
          Email Recovery!!!
        </div>
      ) : (
        ""
      )}
      <Button variant="default" type="button" onClick={handleSubmit}>
        Recover
      </Button>
      <p className="text-sm text-center font-semibold text-muted-foreground text-indigo-500 pt-6">
        {" "}
        <Link to="/auth/passwordresetrequest" className="underline underline-offset-4 hover:text-primary">
          Reset Password
        </Link>{" "}
        or{" "}
        <Link to="/auth/signin" className="underline underline-offset-4 hover:text-primary">
          Sign in
        </Link>{" "}
      </p>
    </div>
  );
};
