import React, { useCallback, useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import { Search, Laptop, Settings as SettingsIcon, BookUser, Minus, Plus, Mail, Phone, Building } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { useDashboardStore } from "@/features/dashboard/dashboard-store";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@/components/ui/accordion";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { DeviceCard } from "./DeviceCard";
import { Customer, InstalledDevice } from "@rtbot-dev/json-schemas";

type CustomerSectionProps = {
  customer: Customer;
  devices: InstalledDevice[];
  selectedDevice: InstalledDevice | null;
  onDeviceSelect: (device: InstalledDevice) => void;
};

const CustomerSection = React.memo(({ customer, devices, selectedDevice, onDeviceSelect }: CustomerSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mb-6">
      <div className="flex items-center space-x-2 mb-3">
        <BookUser className="h-4 w-4 text-muted-foreground" />
        <h3>{customer.contact.emailAddress}</h3>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className={`
            overflow-hidden 
            transition-all 
            duration-200 
            ease-in-out
            ${isExpanded ? "opacity-100 p-3" : "opacity-50 p-0"}`}
        >
          {isExpanded ? (
            <Minus className="h-4 w-4 text-muted-foreground" />
          ) : (
            <Plus className="h-4 w-4 text-muted-foreground" />
          )}
        </button>
      </div>
      <div
        className={`pl-6 mb-3 bg-secondary/20 rounded-md overflow-hidden transition-all duration-200 ease-in-out
          ${isExpanded ? "max-h-40 opacity-100" : "max-h-0 opacity-0 p-0"}`}
      >
        <div className="pl-6 mb-3 space-y-2 bg-secondary/20 p-3 rounded-md">
          <div className="flex items-center space-x-2">
            <Mail className="h-4 w-4 text-muted-foreground" />
            <span className="text-sm">{customer.contact.emailAddress}</span>
          </div>
          {customer.contact.firstName && (
            <div className="flex items-center space-x-2">
              <Building className="h-4 w-4 text-muted-foreground" />
              <span className="text-sm">
                {customer.contact.suffix ? customer.contact.suffix + " " : ""}
                {customer.contact.firstName} {customer.contact.lastName}
              </span>
            </div>
          )}
          {customer.contact.phoneNumber && (
            <div className="flex items-center space-x-2">
              <Phone className="h-4 w-4 text-muted-foreground" />
              <span className="text-sm">{customer.contact.phoneNumber}</span>
            </div>
          )}
        </div>
      </div>

      <div className="space-y-2 pl-6">
        {devices.map((device) => (
          <DeviceCard
            key={device.deviceId}
            device={device}
            isCustomer={false}
            isSelected={selectedDevice?.deviceId === device.deviceId}
            onClick={() => onDeviceSelect(device)}
          />
        ))}
      </div>
    </div>
  );
});

const DevicesSection = ({
  devices,
  customerDevices,
  selectedDevice,
  searchTerm,
  setSearchTerm,
  onDeviceSelect,
  isPartner,
}) => {
  const filteredDevices = React.useMemo(() => {
    return devices.filter(
      (device) =>
        device.deviceId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        device.monitoring?.brand?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        device.monitoring?.refrigerant?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [devices, searchTerm]);

  const filteredCustomerDevices = React.useMemo(() => {
    if (!customerDevices) return [];
    return customerDevices
      .map((customer) => ({
        ...customer,
        devices: customer.devices.filter(
          (device) =>
            device.deviceId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            device.monitoring?.brand?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            device.monitoring?.refrigerant?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            customer.name.toLowerCase().includes(searchTerm.toLowerCase())
        ),
      }))
      .filter((customer) => customer.devices.length > 0);
  }, [customerDevices, searchTerm]);

  const totalCustomerDevices = React.useMemo(
    () => filteredCustomerDevices.reduce((acc, customer) => acc + customer.devices.length, 0),
    [filteredCustomerDevices]
  );

  const DeviceList = ({ devices }) => (
    <ScrollArea className="h-[calc(100vh-480px)]">
      <div className="space-y-2 pr-4">
        {devices.map((device) => (
          <DeviceCard
            key={device.deviceId}
            device={device}
            isCustomer={true}
            isSelected={selectedDevice?.deviceId === device.deviceId}
            onClick={() => onDeviceSelect(device)}
          />
        ))}
      </div>
    </ScrollArea>
  );

  const CustomerDeviceList = () => (
    <ScrollArea className="h-[calc(100vh-480px)]">
      <div className="pr-4">
        {filteredCustomerDevices.map(({ customer, devices }) => (
          <CustomerSection
            key={customer.id}
            customer={customer}
            devices={devices}
            selectedDevice={selectedDevice}
            onDeviceSelect={onDeviceSelect}
          />
        ))}
      </div>
    </ScrollArea>
  );

  return (
    <div className="space-y-4">
      <div className="relative">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder="Search devices..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-8"
        />
      </div>

      {isPartner ? (
        // Partner view with tabs
        <Tabs defaultValue="my-devices" className="w-full">
          <TabsList className="w-full">
            <TabsTrigger value="my-devices" className="flex-1">
              My Devices
              <Badge variant="secondary" className="ml-2">
                {filteredDevices.length}
              </Badge>
            </TabsTrigger>
            <TabsTrigger value="customer-devices" className="flex-1">
              Customer Devices
              <Badge variant="secondary" className="ml-2">
                {totalCustomerDevices}
              </Badge>
            </TabsTrigger>
          </TabsList>

          <TabsContent value="my-devices" className="mt-4">
            <DeviceList devices={filteredDevices} />
          </TabsContent>

          <TabsContent value="customer-devices" className="mt-4">
            <CustomerDeviceList />
          </TabsContent>
        </Tabs>
      ) : (
        // Regular user view - single list
        <div className="mt-4">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-2">
              <h3 className="font-medium">My Devices</h3>
              <Badge variant="secondary">{filteredDevices.length}</Badge>
            </div>
          </div>
          <DeviceList devices={filteredDevices} />
        </div>
      )}
    </div>
  );
};

const SettingsSection = ({ showMetric, toggleShowMetric }) => {
  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <h4 className="text-sm font-medium">Units</h4>
        <RadioGroup
          defaultValue={showMetric ? "metric" : "imperial"}
          onValueChange={(value) => toggleShowMetric(value === "metric")}
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="metric" id="metric" />
            <Label htmlFor="metric">Metric</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="imperial" id="imperial" />
            <Label htmlFor="imperial">Imperial</Label>
          </div>
        </RadioGroup>
      </div>

      <div className="space-y-4">
        <h4 className="text-sm font-medium">Display</h4>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <Checkbox id="showOffline" />
            <Label htmlFor="showOffline">Show offline devices</Label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox id="showUnsubscribed" />
            <Label htmlFor="showUnsubscribed">Show unsubscribed devices</Label>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DashboardDrawer = () => {
  // Only select UI state
  const { drawerOpen, isPartner, showMetric } = useDashboardStore(
    useCallback(
      (state) => ({
        drawerOpen: state.ui.drawerOpen,
        isPartner: state.ui.isPartner,
        showMetric: state.ui.showMetric,
      }),
      []
    )
  );

  // Select device data separately
  const { devices, customerDevices, selectedDevice } = useDashboardStore(
    useCallback(
      (state) => ({
        devices: state.devices,
        customerDevices: state.customerDevices,
        selectedDevice: state.selectedDevice,
      }),
      []
    )
  );

  // Select actions
  const { setDrawerOpen, toggleShowMetric, selectDevice } = useDashboardStore(
    useCallback(
      (state) => ({
        setDrawerOpen: state.setDrawerOpen,
        toggleShowMetric: state.toggleShowMetric,
        selectDevice: state.selectDevice,
      }),
      []
    )
  );

  const [searchTerm, setSearchTerm] = React.useState("");
  const navigate = useNavigate();

  const handleDeviceSelect = (device) => {
    navigate(`/dashboard?deviceId=${device.deviceId}`);
    setDrawerOpen(false);
  };

  return (
    <Sheet open={drawerOpen} onOpenChange={setDrawerOpen}>
      <SheetTrigger asChild>
        <Button variant="outline" className="fixed left-4 top-20 z-40 flex items-center space-x-2">
          <Laptop className="h-4 w-4" />
          <span>Menu</span>
        </Button>
      </SheetTrigger>

      <SheetContent side="left" className="w-[300px] sm:w-[400px] mt-16 p-0">
        <div className="p-4">
          <h2 className="text-lg font-semibold mb-4">Dashboard Menu</h2>

          <Accordion type="single" collapsible defaultValue="devices" className="w-full">
            <AccordionItem value="devices">
              <AccordionTrigger className="text-base">
                <div className="flex items-center space-x-2">
                  <Laptop className="h-4 w-4" />
                  <span>Devices</span>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <DevicesSection
                  devices={devices}
                  customerDevices={customerDevices}
                  selectedDevice={selectedDevice}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  onDeviceSelect={handleDeviceSelect}
                  isPartner={isPartner}
                />
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="settings">
              <AccordionTrigger className="text-base">
                <div className="flex items-center space-x-2">
                  <SettingsIcon className="h-4 w-4" />
                  <span>Settings</span>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <SettingsSection showMetric={showMetric} toggleShowMetric={toggleShowMetric} />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </SheetContent>
    </Sheet>
  );
};
