import React from "react";
import { Loader2 } from "lucide-react";

export const LoadingOverlay = () => {
  return (
    <div className="fixed h-screen inset-0 top-16 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="flex flex-col items-center space-y-4">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
        <p className="text-sm text-muted-foreground">Loading device data...</p>
      </div>
    </div>
  );
};
