import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useDashboardStore } from "@/features/dashboard/dashboard-store";
import { useEffect, useState } from "react";

export const DeviceRenameDialog = ({ deviceName, deviceId, open, onOpenChange }) => {
  const [newName, setNewName] = useState(deviceName);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const updateDeviceName = useDashboardStore((state) => state.updateDeviceName);

  // Reset form when dialog opens
  useEffect(() => {
    if (open) {
      setNewName(deviceName);
      setError("");
    }
  }, [open]);

  const handleRename = async () => {
    if (!newName.trim()) {
      setError("Device name cannot be empty");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      await updateDeviceName(deviceId, newName.trim());
      onOpenChange(false);
    } catch (err) {
      setError("Failed to rename device. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>Rename Device</DialogTitle>
          <DialogDescription>
            Enter a new name for the device {deviceName} ({deviceId})
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <Input
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Enter device name"
            autoFocus
          />
          {error && <p className="text-sm text-destructive">{error}</p>}
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={handleRename} disabled={isLoading}>
            {isLoading ? "Saving..." : "Save"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
