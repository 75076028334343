import { Button } from "@/components/ui/button";

import { useAuthStore } from "../../auth-store";

export const VerifyEmailRequest = () => {
  const { user } = useAuthStore((state) => state);
  const { sendEmailVerification } = useAuthStore((state) => state);

  if (!user) return null;

  return (
    <>
      <div className="relative pt-10 pb-24 flex items-center justify-center ">
        <div className="relative sm:w-[520px] justify-center sm:border">
          <div className="sm:p-8">
            <div className="mx-auto flex w-full flex-col justify-center space-y-3 sm:space-y-6 sm:w-[350px]">
              <div className="flex flex-col text-center">
                <h1 className="text-2xl font-semibold tracking-tight">Email Verification</h1>
                <p className="text-sm text-muted-foreground text-center pb-3 pt-6 sm:pt-10">
                  Request verification of your email address
                </p>
              </div>
              <Button
                onClick={async () => {
                  try {
                    await sendEmailVerification(user);
                    console.log("Send Verification to the user");
                  } catch (error) {
                    console.log("Not possible to send");
                  }
                }}
              >
                Verify Email
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
