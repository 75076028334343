import { SendPasswordResetEmailForm } from "./SendPasswordResetEmailForm";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@/components/ui/theme-provider";
import WhiteLogo from "@/assets/Cyan-black-logo.png";
import DarkLogo from "@/assets/Logo-DarkMode.png";

interface PasswordLayoutProps {
  children: ReactNode;
}

export const PasswordResetLayout = ({ children }: PasswordLayoutProps) => {
  const navigate = useNavigate();
  const { theme } = useTheme();

  const showLogo = () => {
    // console.log("Theme", theme);
    if (theme === "dark") {
      return <img src={DarkLogo} alt="DarkLogo" className="h-10 md:h-12 object-fill"></img>;
    } else {
      return <img src={WhiteLogo} alt="WhiteLogo" className="h-10 md:h-12 object-fill"></img>;
    }
  };
  return (
    <div className="min-h-screen flex flex-col bg-cyan-mostwhite">
      {/* Main content */}
      <div className="flex justify-start overflow-x-hidden pl-2 py-8 sm:p-8">
        <button className="flex items-center sm:pr-6 mb-2 mx-4 hover:cursor-pointer" onClick={() => navigate("/")}>
          {showLogo()}
        </button>
      </div>
      <div className="flex items-center justify-center p-4">
        <div className="w-full max-w-lg grid bg-white rounded-2xl shadow-xl overflow-hidden">
          {/* Left side - Login Form */}
          <div className="p-8 lg:p-12">
            <div className="space-y-6">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
