import { useTheme } from "@/components/ui/theme-provider";
import React, { ReactNode } from "react";
import WhiteLogo from "@/assets/Cyan-black-logo.png";
import DarkLogo from "@/assets/Logo-DarkMode.png";
import { useNavigate } from "react-router-dom";

interface TopBarCalculatorProps {
  children: ReactNode;
}

const TopBarCalculator = ({ children }: TopBarCalculatorProps) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const showLogo = () => {
    // console.log("Theme", theme);
    if (theme === "dark") {
      return <img src={DarkLogo} alt="DarkLogo" className="h-8 md:h-10 object-fill"></img>;
    } else {
      return <img src={WhiteLogo} alt="WhiteLogo" className="h-8 md:h-10 object-fill"></img>;
    }
  };
  return (
    <div className="relative flex flex-col">
      <div className="fixed top-0 w-full z-30 shadow-md">
        <header className="sm:h-auto">
          <div className="flex justify-center">
            <div className="flex w-full h-20 bg-White/30 backdrop-blur items-center justify-center">
              <div className="container max-w-screen-2xl overflow-x-hidden md:flex">
                <button
                  className="flex items-center sm:pr-6 mb-2 mx-4 hover:cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  {showLogo()}
                </button>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className="pt-10 pb-12">{children}</div>
    </div>
  );
};

export default TopBarCalculator;
