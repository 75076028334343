import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Joystick, ArrowDownUp, AlertTriangle, MapPin, Pencil, Check, CircleX } from "lucide-react";
import { DeviceRenameDialog } from "./DeviceRenameDialog";
import { useDashboardStore } from "./dashboard-store";

const DeviceStatus = ({ active, subscribed }) => {
  return (
    <div className="flex items-center space-x-1">
      {subscribed && active ? (
        <>
          <Check className={`h-3 w-3 ${active ? "text-green-500" : "text-green-500"}`} />
          <span className={`text-sm ${active ? "text-green-500" : "text-green-500"}`}>Active</span>
        </>
      ) : (
        <>
          <CircleX className={`h-3 w-3 ${active ? "text-green-500" : "text-yellow-500"}`} />
          <span className={`text-sm ${active ? "text-green-500" : "text-yellow-500"}`}>Disabled</span>
        </>
      )}
    </div>
  );
};

export const DeviceCard = ({ device, isCustomer, isSelected, onClick }) => {
  const isRenameOpen = useDashboardStore((state) => state.ui.isRenameOpen);
  const setIsRenameOpen = useDashboardStore((state) => state.setIsRenameOpen);

  const hasLocation =
    device.monitoring?.location?.address ||
    (device.monitoring?.location?.latitude && device.monitoring?.location?.longitude);

  return (
    <>
      <div
        className={`p-3 mb-2 border rounded-md cursor-pointer hover:bg-muted/50 transition-colors ${
          isSelected ? "bg-muted" : ""
        }`}
        onClick={onClick}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Joystick className="h-4 w-4 text-muted-foreground" />
            <span className="font-medium">{device.name}</span>
            {isCustomer && (
              <Button
                variant="ghost"
                size="sm"
                className="h-6 w-6 p-0"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRenameOpen(true);
                }}
              >
                <Pencil className="h-3 w-3" />
              </Button>
            )}
          </div>
          <DeviceStatus active={device.active} subscribed={device.subscribed} />
        </div>

        <div className="mt-2 space-y-1">
          <div className="text-sm text-muted-foreground">
            {device.monitoring?.brand} {device.monitoring?.tonnage}T
            {device.monitoring?.refrigerant && ` • ${device.monitoring.refrigerant}`}
          </div>

          {hasLocation && (
            <div className="flex items-center space-x-1 text-xs text-muted-foreground">
              <MapPin className="h-3 w-3" />
              <span>
                {device.monitoring?.location?.address ||
                  `${device.monitoring?.location?.latitude.toFixed(
                    4
                  )}, ${device.monitoring?.location?.longitude.toFixed(4)}`}
              </span>
            </div>
          )}
        </div>

        {!device.subscribed && (
          <div className="mt-3 flex justify-center items-center text-sm text-destructive">
            <AlertTriangle className="h-3 w-3 mr-1" />
            No subscription
          </div>
        )}
      </div>

      <DeviceRenameDialog
        deviceName={device.name}
        deviceId={device.deviceId}
        open={isRenameOpen}
        onOpenChange={setIsRenameOpen}
      />
    </>
  );
};
