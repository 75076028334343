import React, { useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ArrowDown } from "lucide-react";

const HVACCalculator = () => {
  const [inputs, setInputs] = useState({
    powerCost: 150,
    maintenanceCost: 200,
    emergencyCalls: 2,
    emergencyCallCost: 250,
    partsCost: 300,
    initialCost: 5000,
    lifespan: 15,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: parseFloat(value) || 0,
    }));
  };

  const calculateAnnualCost = () => {
    const annualPowerCost = Math.round(inputs.powerCost * 12);
    const annualMaintenanceCost = Math.round(inputs.maintenanceCost * 2);
    const annualEmergencyCost = Math.round(inputs.emergencyCalls * inputs.emergencyCallCost);
    const annualDepreciation = Math.round(inputs.initialCost / inputs.lifespan);

    return Math.round(
      annualPowerCost + annualMaintenanceCost + annualEmergencyCost + inputs.partsCost + annualDepreciation
    );
  };

  return (
    <div>
      <div className="text-center text-2xl font-semibold mt-8">HVAC Annual Cost Calculator</div>
      <div className="w-full max-w-2xl mx-auto py-6 px-12">
        The HVAC Cost Calculator helps users estimate the total yearly cost of owning and maintaining an HVAC (Heating,
        Ventilation, and Air Conditioning) unit.
      </div>
      <Card className="w-full max-w-xl mx-auto">
        <CardHeader>
          <CardTitle>HVAC Annual Cost Calculator</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid gap-4">
            <div className="space-y-2">
              <Label htmlFor="powerCost">Monthly Power Consumption Cost ($)</Label>
              <Input
                id="powerCost"
                name="powerCost"
                type="number"
                value={inputs.powerCost}
                onChange={handleInputChange}
                placeholder="Enter monthly power cost"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="maintenanceCost">Cost per Maintenance Visit ($)</Label>
              <Input
                id="maintenanceCost"
                name="maintenanceCost"
                type="number"
                value={inputs.maintenanceCost}
                onChange={handleInputChange}
                placeholder="Enter maintenance visit cost"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="emergencyCalls">Number of Emergency Calls per Year</Label>
              <Input
                id="emergencyCalls"
                name="emergencyCalls"
                type="number"
                value={inputs.emergencyCalls}
                onChange={handleInputChange}
                placeholder="Enter number of emergency calls"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="emergencyCallCost">Average Cost per Emergency Call ($)</Label>
              <Input
                id="emergencyCallCost"
                name="emergencyCallCost"
                type="number"
                value={inputs.emergencyCallCost}
                onChange={handleInputChange}
                placeholder="Enter emergency call cost"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="partsCost">Annual Parts Replacement Cost ($)</Label>
              <Input
                id="partsCost"
                name="partsCost"
                type="number"
                value={inputs.partsCost}
                onChange={handleInputChange}
                placeholder="Enter annual parts cost"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="initialCost">Initial Cost of Unit ($)</Label>
              <Input
                id="initialCost"
                name="initialCost"
                type="number"
                value={inputs.initialCost}
                onChange={handleInputChange}
                placeholder="Enter initial unit cost"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="lifespan">Expected Lifespan (years)</Label>
              <Input
                id="lifespan"
                name="lifespan"
                type="number"
                value={inputs.lifespan}
                onChange={handleInputChange}
                placeholder="Enter expected lifespan"
              />
            </div>
          </div>

          <div className="mt-6 p-4 bg-blue-50 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Annual Cost Breakdown:</h3>
            <div className="space-y-2">
              <p>Power Cost: ${(inputs.powerCost * 12).toLocaleString()}</p>
              <p>Maintenance Cost: ${(inputs.maintenanceCost * 2).toLocaleString()}</p>
              <p>Emergency Calls: ${(inputs.emergencyCalls * inputs.emergencyCallCost).toLocaleString()}</p>
              <p>Parts Replacement: ${inputs.partsCost.toLocaleString()}</p>
              <p>Annual Depreciation: ${Math.round(inputs.initialCost / inputs.lifespan).toLocaleString()}</p>
              <div className="pt-2 border-t border-blue-200">
                <p className="text-xl font-bold">Total Annual Cost: ${calculateAnnualCost().toLocaleString()}</p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default HVACCalculator;
