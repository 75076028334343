import { create } from "zustand";
import { httpApi } from "@/http-api";
import { Customer, InstalledDevice } from "@rtbot-dev/json-schemas";
import { useAuthStore } from "../auth/auth-store";
import { useSensorStore } from "./sensor-store";

type UIState = {
  loadingDevices: boolean;
  showMetric: boolean;
  isPartner: boolean;
  isRenameOpen: boolean;
  drawerOpen: boolean;
  status: string | undefined;
};

type CustomerDevices = {
  customer: Customer;
  devices: InstalledDevice[];
};

type DashboardState = {
  ui: UIState;
  devices: InstalledDevice[];
  customerDevices: CustomerDevices[] | null;
  selectedDevice: InstalledDevice | null;
};

type DashboardActions = {
  loadDevices: () => Promise<void>;
  loadCustomerDevices: () => Promise<void>;
  toggleShowMetric: () => void;
  selectDevice: (deviceId: string) => void;
  setDrawerOpen: (open: boolean) => void;
  setStatus: (status: string | undefined) => void;
  setIsRenameOpen: (open: boolean) => void;
  updateDeviceName: (deviceId: string, newName: string) => Promise<void>;
};

export const useDashboardStore = create<DashboardState & DashboardActions>((set, get) => ({
  ui: {
    loadingDevices: true,
    showMetric: false,
    isPartner: false,
    isRenameOpen: false,
    drawerOpen: true,
    status: undefined,
  },
  devices: [],
  customerDevices: null,
  selectedDevice: null,

  toggleShowMetric: () => set((state) => ({ ui: { ...state.ui, showMetric: !state.ui.showMetric } })),

  setDrawerOpen: (open) => set((state) => ({ ui: { ...state.ui, drawerOpen: open } })),

  setStatus: (status) => set((state) => ({ ui: { ...state.ui, status } })),

  setIsRenameOpen: (open) => set((state) => ({ ui: { ...state.ui, isRenameOpen: open } })),

  selectDevice: (deviceId: string) => {
    const { devices, customerDevices } = get();
    // Find the device in the list of devices or in the list of customer devices
    const device =
      devices.find((d) => d.deviceId === deviceId) ??
      [...customerDevices].flatMap((c) => c.devices).find((d) => d.deviceId === deviceId);

    set((state) => ({
      selectedDevice: device,
      ui: { ...state.ui, drawerOpen: false },
    }));

    if (device.subscribed && device.kernelIds[0]) {
      useSensorStore.getState().cleanupStreams();
      useSensorStore.getState().initializeStreams(device.kernelIds[0]);
    }
  },

  async loadDevices() {
    const { user } = useAuthStore.getState();
    if (!user) return;

    const token = await user.getIdTokenResult();
    const isPartner = token.claims.partner === true;

    set((state) => ({ ui: { ...state.ui, isPartner } }));

    const { data: devices } = await httpApi.getDevices();
    set((state) => ({
      devices,
      ui: { ...state.ui, loadingDevices: false },
    }));

    if (isPartner) {
      get().loadCustomerDevices();
    }
  },

  async loadCustomerDevices() {
    try {
      const { data: customers } = await httpApi.getCustomers();
      const customerDevices = await Promise.all(
        customers.map(async (customer) => {
          const { data: devices } = await httpApi.getCustomerDevices(customer.id);
          return { customer, devices };
        })
      );
      set({ customerDevices });
    } catch (error) {
      console.error("Error loading customer devices:", error);
    }
  },

  async updateDeviceName(deviceId: string, newName: string) {
    try {
      const { data: updatedDevice } = await httpApi.renameDevice(deviceId, newName);
      set((state) => ({
        devices: state.devices.map((device) => (device.deviceId === deviceId ? updatedDevice : device)),
        selectedDevice: state.selectedDevice?.deviceId === deviceId ? updatedDevice : state.selectedDevice,
        customerDevices: state.customerDevices?.map((customer) => ({
          ...customer,
          devices: customer.devices.map((device) => (device.deviceId === deviceId ? updatedDevice : device)),
        })),
      }));
    } catch (error) {
      console.error("Failed to rename device:", error);
      throw error;
    }
  },
}));
