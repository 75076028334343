import React from "react";
import { usePartnerStore } from "../../partner-store";

type Props = {};

export const ConfirmRegistration = (props: Props) => {
  const { customerSetup } = usePartnerStore((state) => state);
  console.log("Email", customerSetup.emailAddress);
  return (
    <>
      <div className="flex justify-center items-center py-24">
        <div className="flex justify-center">
          <div className="flex flex-col grow sm:max-w-screen-lg m-6 sm:m-0 bg-White/30 justify-center space-y-6">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight text-foreground">Done!</h1>
            </div>
            <div className="flex-row justify-center text-lg text-center font-normal space-x-1">
              <p>Device registration succeeded.</p>
              <br />
              <p>
                An email has been sent to <b>{customerSetup.emailAddress}</b> to continue with the account setup.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

//Implement Firebase Send Email with the customer's registration information
