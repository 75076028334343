import { Send } from "lucide-react";
import { PasswordResetLayout } from "./PasswordResetLayout";

export const PasswordResetMailSent = () => {
  return (
    <PasswordResetLayout>
      <div>
        <div className="flex flex-row lg:pt-0 justify-center text-center">
          <h1 className="text-xl md:text-2xl font-semibold tracking-tight text-foreground">EMAIL SENT!</h1>
          <Send size={24} strokeWidth={1.75} className="text-foreground" />
        </div>
        <div className="relative mx-auto flex w-full flex-col justify-center space-y-3 sm:space-y-6">
          <p className="text-sm font-sans text-muted-foreground text-justify text-indigo-500 pt-6 pb-3 sm:pt-10 sm:text-base">
            An email has been sent to your mailbox. Please follow the steps described to continue resetting your
            password.
          </p>
        </div>
      </div>
    </PasswordResetLayout>
  );
};
