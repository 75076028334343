import { HTMLAttributes, SyntheticEvent, useEffect, useState } from "react";
import { cn } from "@/components/utils";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../auth-store";
import { z } from "zod";
import { fromZodError } from "zod-validation-error";

interface SendPasswordResetEmailFormProps extends HTMLAttributes<HTMLDivElement> {}

const emailSchema = z.string().email();

export const UpdateEmailForm = ({ className }: SendPasswordResetEmailFormProps) => {
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const { updateEmail } = useAuthStore((state) => state);
  const { user } = useAuthStore((state) => state);
  const [formInput, setFormInput] = useState({
    email: "",
  });
  const [message, setMessage] = useState({
    message: "",
  });

  if (!user) return null;

  useEffect(() => {
    const { success, error } = emailSchema.safeParse(formInput.email);
    if (error) {
      const validationError = fromZodError(error).toString().replace("Validation error:", "");
      //console.log(validationError);
      setMessage({
        message: validationError,
      });
    } else {
      console.log("Email valid");
    }
    setIsDisable(!success);
  }, [formInput]);

  async function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
  }

  const handleSubmit = async (e: any) => {
    try {
      console.log("There is a user", user, formInput.email);
      await updateEmail(user, formInput.email);
      console.log(formInput.email);
    } catch (error) {
      console.log("error: " + error);
    }
  };

  return (
    <div className={cn("grid gap-6", className)}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              onChange={({ target }) =>
                setFormInput({
                  email: target.value,
                })
              }
            />
          </div>
          <Button disabled={isDisable} onClick={handleSubmit}>
            Send
          </Button>
          {message && isDisable ? (
            <div className="flex justify-center text-sm text-red-300 font-sans">{message.message}</div>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
};
