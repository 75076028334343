import { SendPasswordResetEmailForm } from "./SendPasswordResetEmailForm";
import { PasswordResetLayout } from "./PasswordResetLayout";

export const PasswordResetRequest = () => {
  return (
    <PasswordResetLayout>
      <div>
        <div className="flex flex-row lg:pt-0 justify-center text-center">
          <h1 className="text-xl md:text-2xl font-semibold tracking-tight text-foreground">Reset Password</h1>
        </div>
        <p className="text-sm text-muted-foreground text-left pb-3 pt-6 sm:pt-10">
          Please write your email address below and complete the captcha verification to request a password change.
        </p>
        <SendPasswordResetEmailForm />
      </div>
    </PasswordResetLayout>
  );
};
