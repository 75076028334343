import React, { useEffect, useRef, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from "recharts";
import { format } from "date-fns";
import { merge, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { set } from "ramda";

const MAX_DATA_POINTS = 3000;

type Reading = Record<string, { time: number; value: number }[]>;

const formatXAxis = (unixTime) => {
  return new Date(unixTime).toLocaleTimeString();
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white dark:bg-slate-800 p-2 border border-slate-200 dark:border-slate-700 rounded-lg shadow-lg">
        <p className="text-sm font-medium">{format(new Date(label), "MMM dd, yyyy HH:mm:ss")}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ color: entry.color }} className="text-sm">
            {`${entry.name}: ${entry.value.toFixed(2)} ${entry.unit || ""}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

// Custom Legend Content Component
const CustomLegend = (metric: string, units: string) => (props) => {
  const { payload } = props;

  return (
    <div className="flex flex-col gap-3 p-4 w-48">
      <span>{`${metric} (${units})`}</span>

      {payload?.map((entry, index) => (
        <div
          key={`legend-item-${index}`}
          className="flex items-center cursor-pointer"
          onClick={() => entry.payload.onClick?.()}
        >
          {/* Line symbol */}
          <div className="relative w-8 h-4 mr-2 flex items-center">
            <div className="absolute w-8 h-[2px]" style={{ backgroundColor: entry.color }} />
            <div
              className="absolute left-3 w-2 h-2 rounded-full"
              style={{
                backgroundColor: entry.color,
                border: `2px solid ${entry.color}`,
              }}
            />
          </div>
          <span className="text-sm truncate">{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

const SynchronizedCharts = ({ data }) => {
  const [syncId] = useState("hvacSync");
  const [timeWindow, setTimeWindow] = useState({
    startTime: data.length > 0 ? data[data.length - 100]?.time : null,
    endTime: data.length > 0 ? data[data.length - 1]?.time : null,
  });

  const filteredData = data.filter((point) => {
    // If both times are null, show all data
    if (timeWindow.startTime === null && timeWindow.endTime === null) {
      return true;
    }
    // If start time is null, only filter by end time
    if (timeWindow.startTime === null) {
      return point.time <= timeWindow.endTime;
    }
    // If end time is null, only filter by start time
    if (timeWindow.endTime === null) {
      return point.time >= timeWindow.startTime;
    }
    // Both times are set, filter by both
    return point.time >= timeWindow.startTime && point.time <= timeWindow.endTime;
  });

  // Find indices in the full dataset for the brush
  const startIndex = data.findIndex((point) => point.time === timeWindow.startTime);
  const endIndex = data.findIndex((point) => point.time === timeWindow.endTime);

  return (
    <div className="space-y-1">
      {/* Pressure Chart */}
      <div className="h-52">
        <ResponsiveContainer width="100%" height={150}>
          <LineChart data={filteredData} syncId={syncId} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis hide={true} dataKey="time" tickFormatter={formatXAxis} minTickGap={50} interval="preserveEnd" />
            <YAxis
              hide={true}
              label={{
                value: "PSI",
                angle: -90,
                position: "insideLeft",
                offset: 10,
              }}
              domain={["dataMin - 5", "dataMax + 5"]}
              tick={false}
            />
            <Tooltip content={CustomTooltip} />
            <Legend layout="vertical" verticalAlign="middle" align="left" content={CustomLegend("Pressure", "PSI")} />
            <Line
              type="monotone"
              dataKey="pressureHigh"
              stroke="#a855f7"
              strokeWidth={2}
              name="High Line"
              isAnimationActive={false}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="pressureLow"
              stroke="#22c55e"
              strokeWidth={2}
              name="Low Line"
              isAnimationActive={false}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Temperature Chart */}
      <div className="h-52">
        <ResponsiveContainer width="100%" height={150}>
          <LineChart data={filteredData} syncId={syncId} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis hide={true} dataKey="time" tickFormatter={formatXAxis} minTickGap={50} interval="preserveEnd" />
            <YAxis
              hide={true}
              label={{
                value: "°F",
                angle: -90,
                position: "insideLeft",
                offset: 10,
              }}
              domain={["dataMin - 2", "dataMax + 2"]}
              tick={false}
            />
            <Tooltip content={CustomTooltip} />
            <Legend layout="vertical" verticalAlign="middle" align="left" content={CustomLegend("Temperature", "°F")} />
            <Line
              type="monotone"
              dataKey="temperatureHigh"
              stroke="#ef4444"
              strokeWidth={2}
              name="High Line"
              isAnimationActive={false}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="temperatureLow"
              stroke="#3b82f6"
              strokeWidth={2}
              name="Low Line"
              isAnimationActive={false}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Current Chart */}
      <div className="h-52">
        <ResponsiveContainer width="100%" height={150}>
          <LineChart data={filteredData} syncId={syncId} margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis hide={true} dataKey="time" tickFormatter={formatXAxis} minTickGap={50} tick />
            <YAxis
              hide={true}
              label={{
                value: "Amps",
                angle: -90,
                position: "insideLeft",
                offset: 10,
              }}
              domain={([dataMin, dataMax]) => [Math.max(dataMin - 2, 0), dataMax + 2]}
              tick={false}
            />
            <Tooltip content={CustomTooltip} />
            <Legend layout="vertical" verticalAlign="middle" align="left" content={CustomLegend("Current", "A")} />
            <Line
              type="monotone"
              dataKey="current1"
              stroke="#f59e0b"
              strokeWidth={2}
              name="Current 1"
              isAnimationActive={false}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="current2"
              stroke="#0ea5e9"
              strokeWidth={2}
              name="Current 2"
              isAnimationActive={false}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="current3"
              stroke="#ec4899"
              name="Current 3"
              strokeWidth={2}
              isAnimationActive={false}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      {/* Brush component only */}
      <div className="h-16">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} syncId={syncId} margin={{ top: 0, right: 0, left: 190, bottom: 0 }}>
            <XAxis dataKey="time" tickFormatter={formatXAxis} minTickGap={50} />
            <Brush
              dataKey="time"
              height={30}
              stroke="#8884d8"
              tickFormatter={formatXAxis}
              startIndex={startIndex !== -1 ? startIndex : undefined}
              endIndex={endIndex !== -1 ? endIndex : undefined}
              onChange={(e) => {
                if (e.startIndex === undefined || e.endIndex === undefined) return;

                // Check if we're at the boundaries
                const isAtStart = e.startIndex === 0;
                const isAtEnd = e.endIndex === data.length - 1;

                setTimeWindow({
                  startTime: isAtStart ? null : data[e.startIndex]?.time,
                  endTime: isAtEnd ? null : data[e.endIndex]?.time,
                });
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

type RechartsStreamVisualizerProps = {
  lowLine$: Subject<Reading>;
  highLine$: Subject<Reading>;
  current$: Subject<Reading>;
};

export const RechartsStreamVisualizer = ({ lowLine$, highLine$, current$ }: RechartsStreamVisualizerProps) => {
  const dataRef = useRef([]);
  const subscriptionRef = useRef<Subscription | null>(null);

  useEffect(() => {
    if (!lowLine$ || !highLine$ || !current$) {
      console.warn("Missing one or more data streams");
      return;
    }

    // Clean up previous subscription if it exists
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }

    dataRef.current = [];

    // Create new subscription and store in ref
    subscriptionRef.current = merge(lowLine$, highLine$, current$).subscribe({
      next: (newReadings: Reading) => {
        let newData = [...dataRef.current];

        Object.entries(newReadings).forEach(([key, readings]) => {
          readings.forEach((reading) => {
            const timestamp = reading.time;
            let existingPointIndex = newData.findIndex((point) => point.time === timestamp);

            if (existingPointIndex !== -1) {
              newData[existingPointIndex] = {
                ...newData[existingPointIndex],
                [key]: reading.value,
              };
            } else {
              newData.push({
                time: timestamp,
                [key]: reading.value,
              });
            }
          });
        });

        newData.sort((a, b) => a.time - b.time);

        if (newData.length > MAX_DATA_POINTS) {
          newData = newData.slice(-MAX_DATA_POINTS);
        }

        dataRef.current = newData;
      },
      error: (error) => {
        console.error("Error in data stream:", error);
      },
    });

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, [lowLine$, highLine$, current$]);

  return <SynchronizedCharts data={dataRef.current} />;
};

export default RechartsStreamVisualizer;
