import React, { HTMLAttributes, useEffect, useState } from "react";
import { cn } from "@/components/utils";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import z from "zod";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../auth-store";
import { fromZodError } from "zod-validation-error";
import { useToast } from "@/components/ui/use-toast";
import { ToastAction } from "@radix-ui/react-toast";
import { Eye, EyeOff } from "lucide-react";

interface SetPasswordFormProps extends HTMLAttributes<HTMLDivElement> {
  code: string;
}

const validationSchema = z
  .object({
    password: z.string().min(6),
    confirmPassword: z.string(),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: "custom",
        message: "The passwords did not match",
      });
    }
  });

export const SetNewPasswordForm = ({ className, code }: SetPasswordFormProps) => {
  const { verifyPasswordResetCode, confirmPasswordReset } = useAuthStore((state) => state);
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState<boolean>(false);
  const [formInput, setFormInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const [message, setMessage] = useState({
    message: "",
  });
  const [typeInput, setTypeInput] = useState("password");
  const [typeInputConfirm, setTypeInputConfim] = useState("password");

  useEffect(() => {
    const { success, error } = validationSchema.safeParse(formInput);
    if (error) {
      const validationError = fromZodError(error).toString().replace("Validation error:", "");
      setMessage({
        message: validationError,
      });
    } else {
      console.log("Password Match");
    }
    setIsDisable(!success);
  }, [formInput]);

  const handleUserInput = (key: string, value: string) => {
    setFormInput({
      ...formInput,
      [key]: value,
    });
  };

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
  }

  const handleSubmit = async (e) => {
    try {
      await verifyPasswordResetCode(code);
      await confirmPasswordReset(code, formInput.password);
      //console.log("Password", formInput.password);
      navigate("/auth/setnewpasswordsuccess");
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Not possible change your password at this time.",
        className: "flex flex-inline py-3",
        action: (
          <ToastAction
            className="flex px-3 my-3 border-2 rounded-lg text-sm text-center"
            altText="Retry"
            onClick={() => {
              navigate("/auth/password-reset");
            }}
          >
            Retry
          </ToastAction>
        ),
      });
    }
  };

  const showEyeInputConfirm = () => {
    setIsVisibleConfirm(!isVisibleConfirm);
    return console.log("Visible input", isVisibleConfirm);
  };

  return (
    <div className={cn("grid gap-6", className)}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <p className="text-sm text-start font-bold text-muted-foreground">Password</p>
            <Label className="sr-only" htmlFor="password"></Label>
            <div className="relative group">
              <Input
                id="password"
                placeholder="********"
                type={typeInput}
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                onChange={({ target }) => {
                  handleUserInput(target.id, target.value);
                }}
                className="z-0"
              />
              {isVisible ? (
                <button
                  className="hidden group-hover:block hover:cursor-pointer z-30"
                  onClick={() => {
                    setIsVisible(!isVisible);
                    setTypeInput("password");
                  }}
                >
                  <Eye className="absolute h-4 w-4 m-4 right-1 top-0 items-center" />
                </button>
              ) : (
                <button
                  className="hover:cursor-pointer z-30"
                  onClick={() => {
                    setIsVisible(!isVisible);
                    setTypeInput("string");
                  }}
                >
                  <EyeOff className="hidden group-hover:block absolute h-4 w-4 m-4 right-1 top-0 items-center" />
                </button>
              )}
            </div>

            <p className="text-sm text-start font-bold text-muted-foreground">Confirm your password</p>
            <Label className="sr-only" htmlFor="password"></Label>
            <div className="relative group">
              <Input
                id="confirmPassword"
                placeholder="********"
                type={typeInputConfirm}
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                onChange={({ target }) => {
                  handleUserInput(target.id, target.value);
                }}
              />
              {isVisibleConfirm ? (
                <button
                  className="hidden group-hover:block hover:cursor-pointer z-30"
                  onClick={() => {
                    setIsVisibleConfirm(!isVisibleConfirm);
                    setTypeInputConfim("password");
                  }}
                >
                  <Eye className="absolute h-4 w-4 m-4 right-1 top-0 items-center" />
                </button>
              ) : (
                <button
                  className="hidden group-hover:block hover:cursor-pointer z-30"
                  onClick={() => {
                    setIsVisibleConfirm(!isVisibleConfirm);
                    setTypeInputConfim("string");
                  }}
                >
                  <EyeOff className="absolute h-4 w-4 m-4 right-1 top-0 items-center" />
                </button>
              )}
            </div>
          </div>
          {message && isDisable ? (
            <div className="flex justify-center text-sm text-red-300 font-sans">{message.message}</div>
          ) : (
            ""
          )}
          <div className="flex justify-center font-sans mt-2">
            <Button variant="default" type="button" disabled={isDisable} onClick={handleSubmit}>
              Reset
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

function stopPropagation() {
  throw new Error("Function not implemented.");
}
