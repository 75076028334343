import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { DialogTitle } from "@radix-ui/react-dialog";
import { Gem, NotebookPen, Rocket, X } from "lucide-react";
import { useState } from "react";

type Props = {};

export const WelcomeUser = (props: Props) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Welcome</Button>
      </DialogTrigger>
      <DialogContent className="max-w-screen-2xl">
        <DialogTitle></DialogTitle>
        <div className="p-8">
          <div className="flex flex-col space-y-2 text-center justify-center">
            <h1 className="text-xl font-semibold tracking-tight sm:text-2xl">Welcome to Cool Guardian!</h1>
            <p className="flex w-full justify-center font-semibold text-xl text-muted-foreground pb-3 pt-6 sm:pt-10">
              All about the good health of your HVAC in one place
            </p>
          </div>
          <div className="grid grid-cols-3 gap-8 justify-center">
            <div className="flex flex-col justify-center">
              <div className="flex justify-center text-foreground/20">
                {" "}
                <NotebookPen className="h-[40px] w-[40px] my-4" />
              </div>
              <h2 className="flex justify-center">What do the roles consist of?</h2>
              <p className="flex w-full text-sm pb-3 pt-6 sm:pt-10 justify-self-center text-start">
                The client role allows you to successfully access all registered devices and monitor their records in
                real time, as well as perform security verification operations on your account.
              </p>
              <div className="flex flex-row mx-auto justify-center items-center px-4 bg-primary/90 rounded-md">
                <a
                  href="#"
                  className="my-2 gap-2 font-medium leading-none hover:pointer hover:accent-Black hover:underline"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <div className="flex justify-center text-foreground/20">
                {" "}
                <Rocket className="h-[40px] w-[40px] my-4" />
              </div>
              <h2 className="flex justify-center">What do the roles consist of?</h2>
              <p className="flex w-full text-sm text-muted-foreground pb-3 pt-6 sm:pt-10 justify-self-center text-start">
                The client role allows you to successfully access all registered devices and monitor their records in
                real time, as well as perform security verification operations on your account.
              </p>
              <div className="flex flex-row mx-auto justify-center items-center px-4 bg-primary/90 rounded-md">
                <a
                  href="#"
                  className="my-2 gap-2 font-medium leading-none hover:pointer hover:accent-Black hover:underline"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <div className="flex justify-center text-foreground/20">
                {" "}
                <Gem className="h-[40px] w-[40px] my-4" />
              </div>
              <h2 className="flex justify-center">What do the roles consist of?</h2>
              <p className="flex w-full text-sm text-muted-foreground pb-3 pt-6 sm:pt-10 justify-self-center text-start">
                The client role allows you to successfully access all registered devices and monitor their records in
                real time, as well as perform security verification operations on your account.
              </p>
              <div className="flex flex-row mx-auto justify-center items-center px-4 bg-primary/90 rounded-md">
                <a
                  href="#"
                  className="my-2 gap-2 font-medium leading-none hover:pointer hover:accent-Black hover:underline"
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeUser;
