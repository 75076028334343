import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Icons } from "@/components/ui/icons";
import { Check } from "lucide-react";
import { BundlePrice, usePaymentStore } from "../payment-store";

export type PaymentOptionProps = {
  price: BundlePrice;
  isLoading: boolean;
  select: (recurringPriceId: string, oneTimePriceId?: string) => void;
  highlighted: boolean;
  onMouseEnter: () => void;
};

export const PaymentOption = ({ price, select, isLoading, highlighted, onMouseEnter }: PaymentOptionProps) => {
  const recurringInterval = price.recurring.recurring?.interval === "year" ? "Annual" : "Monthly";
  const recurringTitle = price.recurring.nickname;
  const { setSelectedPaymentOption } = usePaymentStore();
  const title = recurringTitle;

  const descriptionRecurring = price.recurring.metadata.description;
  const descriptionOneTime = price.oneTime?.metadata.description;
  const priceRecurring = Math.floor(price.recurring.unitAmount / 100);
  const priceOneTime = Math.floor(price.oneTime?.unitAmount / 100);
  const recurringPriceId = price.recurring.id;
  const totalPrice = price.oneTime ? priceRecurring + priceOneTime : priceRecurring;
  const features = price.recurring.metadata.features.split(",");
  const oneTimePriceId = price.oneTime?.id;

  const handleSelect = () => {
    console.log("title", title, priceRecurring, descriptionRecurring, priceOneTime, totalPrice, features);
    setSelectedPaymentOption(
      title,
      priceRecurring,
      descriptionRecurring,
      totalPrice,
      oneTimePriceId,
      priceOneTime,
      features
    );
    select(recurringPriceId, oneTimePriceId);
  };
  return (
    <div onMouseEnter={onMouseEnter} className={`p-1 rounded-xl ${highlighted ? "ring-4 ring-primary" : ""}`}>
      <Card className="sm:w-[320px] sm:h-[530px] shadow-xl shadow-primary/20 font-inter">
        <CardHeader className="p-4">
          <CardTitle>
            <div className="flex flex-row justify-center">
              <div className="font-semibold font-inter text-xl">{title} Plan</div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className="font-inter tracking-tighter">
          <div className="space-y-4">
            <div className="flex flex-col justify-center items-center border-y border-input py-8">
              <p className="text-4xl font-semibold tracking-wide">${priceRecurring}</p>
              <p className="text-sm text-muted-foreground mt-2">{descriptionRecurring}</p>
            </div>

            {oneTimePriceId && (
              <div className="flex flex-row justify-between text-lg font-normal">
                <p>Device</p>
                <p>${priceOneTime}</p>
              </div>
            )}
            <div className="flex flex-row justify-between text-lg font-bold">
              <p>Total</p>
              <p>${totalPrice}</p>
            </div>
            <Button
              disabled={isLoading}
              variant={`${highlighted ? "default" : "outline"}`}
              className="w-full border-primary border-2"
              onClick={handleSelect}
            >
              <p className="font-bold">
                {isLoading ? <Icons.spinner className="mr-2 h-4 w-4 animate-spin" /> : "Select"}
              </p>
            </Button>
            <div className="flex flex-col text-wrap pt-4">
              <h2 className="text-sm font-semibold text-sm">Subscription features:</h2>
              <u className="no-underline pt-4">
                {features.map((feature, index) => (
                  <li key={index} className="flex flex-row list-none">
                    <div>
                      <Check className="h-5 w-5 text-primary mr-2" />
                    </div>
                    <p className="text-sm">{feature}</p>
                  </li>
                ))}
              </u>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
