import { useEffect, useState } from "react";
import { ShadcnJSONForms } from "@rtbot-dev/jsonforms-shadcn";
import { useStepper } from "@/components/ui/stepper";
import { Button } from "@/components/ui/button";
import { usePartnerStore } from "../../partner-store";
import { NewCustomerSetupSchema } from "@rtbot-dev/json-schemas";
import { useSearchParams } from "react-router-dom";

// since we collect the customer information in a separate form, we need to
// split the schema into two parts, the customer and the equipment ones.
const schema = {
  ...NewCustomerSetupSchema,
};

// remove the "monitoring" property from the schema
delete schema.properties.monitoring;
schema.required = schema.required.filter((r) => r !== "monitoring");

const uiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Label",
      text: "Customer Information",
    },
    {
      type: "HorizontalLayout",
      elements: [
        { type: "Control", scope: "#/properties/installationId" },
        { type: "Control", scope: "#/properties/firstName" },
        { type: "Control", scope: "#/properties/lastName" },
        { type: "Control", scope: "#/properties/emailAddress" },
        { type: "Control", scope: "#/properties/phoneNumber" },
      ],
    },
  ],
};

export const NewCustomerRegistrationForm = () => {
  const { customerSetup, setCustomerSetup } = usePartnerStore((state) => state);
  const [valid, setValid] = useState(false);
  const { nextStep } = useStepper();

  const [searchParams] = useSearchParams();
  const [computedSchema, setComputedSchema] = useState(schema);

  useEffect(() => {
    // TODO: fix this
    // if we have an installationId search param, update the schema to make the installationId
    // field an enum with a single value
    const installationId = searchParams.get("installationId");
    if (installationId) {
      setComputedSchema({
        ...schema,
        properties: {
          ...schema.properties,
          installationId: {
            type: "string",
            // @ts-ignore
            enum: [installationId],
          },
        },
      });
    }
  }, [searchParams]);

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full p-8 sm:max-w-xl">
          <ShadcnJSONForms
            schema={computedSchema}
            uischema={uiSchema}
            data={customerSetup}
            onChange={(e: any) => {
              // if there are no errors, update the local data source
              console.log("Data source updated", e);
              if (!e.errors || (e.errors.length === 0 && Object.keys(e.data).length > 0)) {
                console.log("no errors");
                setCustomerSetup(e.data);
                setValid(true);
              } else {
                console.log("errors");
                setValid(false);
              }
            }}
          />
          <div className="flex w-full gap-2 mt-8">
            <Button
              disabled={!valid}
              onClick={() => {
                nextStep();
              }}
              size="sm"
              variant="default"
              className="w-full"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
