import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import * as echarts from "echarts/core";
import { BarChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import TopBarCalculator from "./TopBarCalculator";
import { useSearchParams } from "react-router-dom";
import { set } from "ramda";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
]);

const formatCurrency = (value: number, maximumFractionDigits: number = 0) =>
  value >= 0
    ? `$${value.toLocaleString(undefined, { maximumFractionDigits })}`
    : `-$${Math.abs(value).toLocaleString(undefined, { maximumFractionDigits })}`;
const formatPercent = (value: number) => `${value.toFixed(1)}%`;

// Common components
const ROICalculatorIntro = () => {
  return (
    <Card className="mb-6">
      <CardContent className="pt-6">
        <div className="max-w-3xl mx-auto space-y-4">
          <h1 className="text-2xl font-bold text-center text-blue-900">Cool Guardian ROI Calculator</h1>
          <p className="text-gray-600 text-center">
            Estimate your potential savings and return on investment with Cool Guardian's intelligent HVAC monitoring
            system
          </p>
          <div className="grid md:grid-cols-3 gap-4 mt-6">
            <div className="p-4 bg-blue-50 rounded-lg">
              <h3 className="font-semibold text-blue-900 mb-2">Cost Reduction</h3>
              <p className="text-sm text-gray-600">
                Calculate potential savings from reduced emergency calls, optimized maintenance, and extended equipment
                lifespan
              </p>
            </div>
            <div className="p-4 bg-blue-50 rounded-lg">
              <h3 className="font-semibold text-blue-900 mb-2">ROI Analysis</h3>
              <p className="text-sm text-gray-600">
                View projected returns across conservative, expected, and optimistic scenarios over a 5-year period
              </p>
            </div>
            <div className="p-4 bg-blue-50 rounded-lg">
              <h3 className="font-semibold text-blue-900 mb-2">Fleet Optimization</h3>
              <p className="text-sm text-gray-600">
                See how preventive maintenance and real-time monitoring can enhance your HVAC fleet's performance
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const ScenarioCard = ({ title, scenario, yearlyData, scenarioConfig, baseCosts, inputs }) => {
  // Calculate annual savings components for the full effectiveness (year 2+)
  const calculateFullYearSavings = () => {
    const improvements = scenarioConfig;

    const emergencySavings = baseCosts.emergency * (improvements.emergencyCallReduction / 100);
    const partsSavings = baseCosts.parts * (improvements.partsReduction / 100);
    const lifespanSavings = baseCosts.replacement * (improvements.lifespanExtensionPercent / 100);
    const additionalMaintenanceCost =
      inputs.numUnits * inputs.scheduledVisitCost * improvements.additionalMaintenanceVisits;

    return {
      emergencySavings,
      partsSavings,
      lifespanSavings,
      additionalMaintenanceCost,
      totalSavings: emergencySavings + partsSavings + lifespanSavings - additionalMaintenanceCost,
    };
  };

  const fullYearSavings = calculateFullYearSavings();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title} Scenario</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse text-sm">
            <thead>
              <tr className="bg-blue-50">
                <th className="border p-2 text-center">Year</th>
                <th className="border p-2 text-center">Base</th>
                <th className="border p-2 text-center">
                  Cool <br />
                  Guardian
                </th>
                <th className="border p-2 text-center">
                  Base + <br />
                  Cool Guardian
                </th>
                <th className="border p-2 text-center">
                  Estimated
                  <br />
                  Saving*
                </th>
                <th className="border p-2 text-center">Final</th>
                <th className="border p-2 text-center">Saving</th>
                <th className="border p-2 text-center">
                  Cumulative
                  <br />
                  Investment
                </th>
                <th className="border p-2 text-center">
                  Cumulative
                  <br />
                  Savings
                </th>
                <th className="border p-2 text-center">
                  Return on
                  <br />
                  Investment
                </th>
              </tr>
            </thead>
            <tbody>
              {yearlyData.map((yearData, index) => {
                let cumulativeInvestment = 0;
                let cumulativeSavings = 0;

                for (let i = 0; i <= index; i++) {
                  const yearScenario = yearlyData[i].scenarios[scenario];
                  cumulativeInvestment += yearScenario.coolGuardianTotal;
                  cumulativeSavings += yearlyData[i].baseCosts.total - yearScenario.operationalTotal;
                }

                const scenarioData = yearData.scenarios[scenario];
                const annualCostWithoutCG = yearData.baseCosts.total;
                const basePlusCG = yearData.baseCosts.total + scenarioData.coolGuardianTotal;
                const annualCostWithCG = scenarioData.operationalTotal;
                const annualSavings = yearData.baseCosts.total - annualCostWithCG;

                return (
                  <tr key={yearData.year} className="hover:bg-gray-50">
                    <td className="border p-2 text-left font-medium">{yearData.year}</td>
                    <td className="border p-2 text-right">{formatCurrency(annualCostWithoutCG)}</td>
                    <td className="border p-2 text-right">{formatCurrency(scenarioData.coolGuardianTotal)}</td>
                    <td className="border p-2 text-right">{formatCurrency(basePlusCG)}</td>
                    <td className="border p-2 text-right text-green-600">
                      {formatCurrency(-fullYearSavings.totalSavings)}
                    </td>
                    <td className="border p-2 text-right">{formatCurrency(annualCostWithCG)}</td>
                    <td className="border p-2 text-right text-green-600">{formatCurrency(annualSavings)}</td>
                    <td className="border p-2 text-right text-blue-600">{formatCurrency(cumulativeInvestment)}</td>
                    <td className="border p-2 text-right text-green-600">{formatCurrency(cumulativeSavings)}</td>
                    <td className="border p-2 text-right font-medium">
                      {formatPercent(((cumulativeSavings - cumulativeInvestment) / cumulativeInvestment) * 100)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="mt-6 p-4 bg-gray-50 rounded-lg">
          <h4 className="font-semibold text-lg mb-4">* Estimated Savings Breakdown (Full Potential)</h4>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <div className="flex justify-between items-center mb-1">
                  <span className="text-gray-600">
                    Emergency Call Reduction ({scenarioConfig.emergencyCallReduction}%)
                  </span>
                  <span className="text-green-600 font-medium">{formatCurrency(fullYearSavings.emergencySavings)}</span>
                </div>
                <div className="text-sm text-gray-500">
                  From {inputs.emergencyCallsPerYear} to{" "}
                  {(inputs.emergencyCallsPerYear * (1 - scenarioConfig.emergencyCallReduction / 100)).toFixed(1)}{" "}
                  calls/year
                </div>
              </div>

              <div>
                <div className="flex justify-between items-center mb-1">
                  <span className="text-gray-600">Parts Cost Reduction ({scenarioConfig.partsReduction}%)</span>
                  <span className="text-green-600 font-medium">{formatCurrency(fullYearSavings.partsSavings)}</span>
                </div>
                <div className="text-sm text-gray-500">
                  From {formatCurrency(inputs.annualPartsCost)} to{" "}
                  {formatCurrency(inputs.annualPartsCost * (1 - scenarioConfig.partsReduction / 100))}
                </div>
              </div>

              <div>
                <div className="flex justify-between items-center mb-1">
                  <span className="text-gray-600">
                    Extended Lifespan Impact ({scenarioConfig.lifespanExtensionPercent}%)
                  </span>
                  <span className="text-green-600 font-medium">{formatCurrency(fullYearSavings.lifespanSavings)}</span>
                </div>
                <div className="text-sm text-gray-500">
                  Equipment life extended from {inputs.expectedLifespan} to{" "}
                  {(inputs.expectedLifespan * (1 + scenarioConfig.lifespanExtensionPercent / 100)).toFixed(1)} years
                </div>
              </div>

              <div>
                <div className="flex justify-between items-center mb-1 text-gray-600">
                  <span>Additional Maintenance ({scenarioConfig.additionalMaintenanceVisits} visits/year)</span>
                  <span className="text-red-600 font-medium">
                    -{formatCurrency(fullYearSavings.additionalMaintenanceCost)}
                  </span>
                </div>
                <div className="text-sm text-gray-500">
                  Additional preventive maintenance cost per HVAC unit: $
                  {(inputs.scheduledVisitCost * scenarioConfig.additionalMaintenanceVisits).toFixed(0)}/year
                </div>
              </div>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-sm">
              <div className="space-y-3">
                <div className="flex justify-between items-center pb-2 border-b">
                  <span className="font-medium">Gross Annual Savings</span>
                  <span className="text-green-600 font-medium">
                    {formatCurrency(
                      fullYearSavings.emergencySavings + fullYearSavings.partsSavings + fullYearSavings.lifespanSavings
                    )}
                  </span>
                </div>

                <div className="flex justify-between items-center text-red-600">
                  <span>Additional Maintenance Cost</span>
                  <span>-{formatCurrency(fullYearSavings.additionalMaintenanceCost)}</span>
                </div>

                <div className="flex justify-between items-center pt-2 border-t text-lg">
                  <span className="font-bold">Net Annual Saving (Full Potential)</span>
                  <span className="text-green-600 font-bold">{formatCurrency(fullYearSavings.totalSavings)}</span>
                </div>
              </div>

              <div className="mt-4 pt-4 border-t">
                <p className="text-sm text-gray-500">
                  This breakdown shows the full potential savings after the initial ramp-up period. First-year savings
                  may be lower than this values as improvements are implemented.
                </p>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export const ROICalculator = () => {
  const [searchParams] = useSearchParams();
  const hardwareCostPerUnit = parseFloat(searchParams.get("hardwareCostPerUnit") ?? "300");
  const subscriptionMonthlyCost = parseFloat(searchParams.get("subscriptionMonthlyCost") ?? "15");

  const chartRef = useRef<HTMLDivElement>(null);
  const [echartsInstance, setEchartsInstance] = useState<echarts.ECharts>();
  const [yearlyData, setYearlyData] = useState<any[]>([]);

  const [inputs, setInputs] = useState({
    numUnits: 100,
    maintenanceVisitsPerYear: 1,
    scheduledVisitCost: 150,
    emergencyCallsPerYear: 25,
    emergencyCallCost: 400,
    annualPartsCost: 15000,
    expectedLifespan: 12,
    replacementCostPerUnit: 7000,
  });

  const scenarios = {
    conservative: {
      emergencyCallReduction: 30,
      partsReduction: 20,
      lifespanExtensionPercent: 15,
      additionalMaintenanceVisits: 0.5,
    },
    expected: {
      emergencyCallReduction: 50,
      partsReduction: 30,
      lifespanExtensionPercent: 25,
      additionalMaintenanceVisits: 0,
    },
    optimistic: {
      emergencyCallReduction: 70,
      partsReduction: 40,
      lifespanExtensionPercent: 35,
      additionalMaintenanceVisits: 0,
    },
  };

  const [baseCosts, setBaseCosts] = useState({
    scheduledMaintenance: inputs.numUnits * inputs.scheduledVisitCost * inputs.maintenanceVisitsPerYear,
    emergency: inputs.emergencyCallsPerYear * inputs.emergencyCallCost,
    parts: inputs.annualPartsCost,
    replacement: (inputs.numUnits / inputs.expectedLifespan) * inputs.replacementCostPerUnit,
    replacementsPerYear: inputs.numUnits / inputs.expectedLifespan,
    total:
      inputs.numUnits * inputs.scheduledVisitCost * inputs.maintenanceVisitsPerYear +
      inputs.emergencyCallsPerYear * inputs.emergencyCallCost +
      inputs.annualPartsCost +
      (inputs.numUnits / inputs.expectedLifespan) * inputs.replacementCostPerUnit +
      inputs.numUnits / inputs.expectedLifespan,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => {
      const newInputs = {
        ...prev,
        [name]: parseFloat(value) || 0,
      };

      if (name === "numUnits") {
        const numUnits = parseFloat(value) || 0;
        newInputs.annualPartsCost = numUnits * 150;
        newInputs.emergencyCallsPerYear = numUnits / 4;
      }

      return newInputs;
    });
  };

  const calculateYearlyData = () => {
    const years = 5;
    const yearlyData = [];

    for (let year = 1; year <= years; year++) {
      const rampUpFactor = 1; //Math.min(1, year / 2); // 50% effectiveness in year 1, 100% after
      const yearResults = {
        year,
        baseCosts: { ...baseCosts },
        scenarios: {} as any,
      };

      // Calculate results for each scenario
      Object.entries(scenarios).forEach(([scenarioName, improvements]) => {
        const improvedCosts = {
          scheduledMaintenance:
            inputs.numUnits *
            inputs.scheduledVisitCost *
            (inputs.maintenanceVisitsPerYear + improvements.additionalMaintenanceVisits),
          emergency: baseCosts.emergency * (1 - (improvements.emergencyCallReduction * rampUpFactor) / 100),
          parts: baseCosts.parts * (1 - (improvements.partsReduction * rampUpFactor) / 100),
          replacement:
            (inputs.numUnits * inputs.replacementCostPerUnit) /
            (inputs.expectedLifespan * (1 + (improvements.lifespanExtensionPercent * rampUpFactor) / 100)),
        };

        const coolGuardianCosts = {
          hardware: year === 1 ? inputs.numUnits * hardwareCostPerUnit : 0,
          subscription:
            inputs.numUnits *
            12 *
            subscriptionMonthlyCost *
            (1 - 0.1) * // 10% annual Saving
            (inputs.numUnits >= 80 ? 0.7 : 1), // 30% bulk Saving for 80+ units
        };

        yearResults.scenarios[scenarioName] = {
          ...improvedCosts,
          coolGuardianTotal: coolGuardianCosts.hardware + coolGuardianCosts.subscription,
          operationalTotal: Object.values(improvedCosts).reduce((a, b) => a + b, 0),
          total:
            Object.values(improvedCosts).reduce((a, b) => a + b, 0) +
            coolGuardianCosts.hardware +
            coolGuardianCosts.subscription,
        };
      });

      yearlyData.push(yearResults);
    }

    setYearlyData(yearlyData);
    return yearlyData;
  };

  const calculateBaseCosts = () => {
    const scheduledMaintenance = inputs.numUnits * inputs.scheduledVisitCost * inputs.maintenanceVisitsPerYear;
    const emergency = inputs.emergencyCallsPerYear * inputs.emergencyCallCost;
    const parts = inputs.annualPartsCost;
    const replacement = (inputs.numUnits / inputs.expectedLifespan) * inputs.replacementCostPerUnit;
    const replacementsPerYear = inputs.numUnits / inputs.expectedLifespan;
    const total = scheduledMaintenance + emergency + parts + replacement;
    setBaseCosts({
      scheduledMaintenance,
      emergency,
      parts,
      replacement,
      replacementsPerYear,
      total,
    });
  };

  const calculateChartData = () => {
    const data = {
      years: Array.from({ length: 5 }, (_, i) => `Year ${i + 1}`),
      conservative: [],
      expected: [],
      optimistic: [],
    };

    ["conservative", "expected", "optimistic"].forEach((scenario) => {
      let cumulativeSavings = 0;
      let cumulativeInvestment = 0;

      data[scenario] = yearlyData.map((yearData) => {
        const savings = yearData.baseCosts.total - yearData.scenarios[scenario].operationalTotal;
        const investment = yearData.scenarios[scenario].coolGuardianTotal;

        cumulativeSavings += savings;
        cumulativeInvestment += investment;

        return ((cumulativeSavings - cumulativeInvestment) / cumulativeInvestment) * 100;
      });
    });

    return data;
  };
  // Update chart when data changes
  useLayoutEffect(() => {
    if (!chartRef.current) return;

    const chart = echarts.init(chartRef.current);
    setEchartsInstance(chart);

    const data = calculateChartData();

    const option = {
      title: {
        text: "Cool Guardian ROI Analysis",
        subtext: "Return on Investment based on cumulative savings and costs",
        left: "center",
        top: 0,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "cross" },
        formatter: function (params) {
          return `${params[0].name}<br/>
            Optimistic: ${params[0].data.toFixed(1)}%<br/>
            Expected: ${params[2].data.toFixed(1)}%<br/>
            Conservative: ${params[1].data.toFixed(1)}%`;
        },
      },
      legend: {
        data: ["Optimistic", "Conservative", "Expected ROI"],
        bottom: 0,
      },
      grid: {
        left: "5%",
        right: "5%",
        bottom: "15%",
        top: "15%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: data.years,
        name: "Year",
        nameLocation: "middle",
        nameGap: 35,
      },
      yAxis: {
        type: "value",
        name: "ROI (%)",
        nameLocation: "middle",
        nameGap: 50,
        axisLabel: { formatter: "{value}%" },
      },
      series: [
        {
          name: "Optimistic",
          type: "bar",
          stack: "ROI",
          data: data.years.map((_, i) => data.optimistic[i] - data.conservative[i]),
          itemStyle: {
            color: "#16a34a",
            opacity: 0.3,
          },
          barWidth: "40%",
          z: 1,
        },
        {
          name: "Conservative",
          type: "bar",
          stack: "ROI",
          data: data.conservative,
          itemStyle: {
            color: "#94a3b8",
            opacity: 0.3,
          },
          barWidth: "40%",
          z: 1,
        },
        {
          name: "Expected ROI",
          type: "line",
          data: data.expected,
          symbol: "circle",
          symbolSize: 8,
          itemStyle: { color: "#2563eb" },
          lineStyle: { width: 3 },
          z: 2,
        },
      ],
    };

    chart.setOption(option);
  }, [yearlyData]);

  // Handle chart resizing
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      echartsInstance?.resize();
    });

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [echartsInstance]);

  const [coolGuardianCosts, setCoolGuardianCosts] = useState(null);

  const calculateCoolGuardianCosts = () => {
    // Hardware cost (one-time)
    const totalHardwareCost = inputs.numUnits * hardwareCostPerUnit;

    // Subscription calculations
    const baseSubscriptionCost = subscriptionMonthlyCost * 12 * inputs.numUnits;

    // Discounts
    const annualDiscount = 0.1; // 10% annual subscription discount
    const bulkDiscount = inputs.numUnits >= 80 ? 0.3 : 0; // 30% bulk discount for 80+ units

    // Calculate final subscription cost
    const subscriptionAfterAnnualDiscount = baseSubscriptionCost * (1 - annualDiscount);
    const finalSubscriptionCost = subscriptionAfterAnnualDiscount * (1 - bulkDiscount);

    // Monthly per-unit cost after discounts
    const effectiveMonthlyPerUnit = finalSubscriptionCost / 12 / inputs.numUnits;

    setCoolGuardianCosts({
      hardware: {
        perUnit: hardwareCostPerUnit,
        total: totalHardwareCost,
      },
      subscription: {
        baseMonthlyPerUnit: subscriptionMonthlyCost,
        baseAnnual: baseSubscriptionCost,
        annualDiscount: baseSubscriptionCost * annualDiscount,
        bulkDiscount: subscriptionAfterAnnualDiscount * bulkDiscount,
        final: finalSubscriptionCost,
        effectiveMonthlyPerUnit,
      },
      total: {
        firstYear: totalHardwareCost + finalSubscriptionCost,
        recurring: finalSubscriptionCost,
      },
    });
  };

  // Calculate data when inputs change
  useEffect(() => {
    calculateBaseCosts();
  }, [inputs, hardwareCostPerUnit, subscriptionMonthlyCost]);

  useEffect(() => {
    calculateYearlyData();
    calculateCoolGuardianCosts();
  }, [baseCosts]);

  return (
    <TopBarCalculator>
      <div
        className="w-full mt-6 h-96"
        style={{ background: 'url("/roi-header.webp")', backgroundPositionY: "70%", backgroundSize: "cover" }}
      />
      <div className="pt-6">
        <div className="w-full max-w-4xl mx-auto space-y-6">
          <ROICalculatorIntro />

          {/* Input Form */}
          <Card>
            <CardHeader>
              <CardTitle>Fleet Parameters</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 gap-4">
                {/* Input fields */}
                {Object.entries({
                  numUnits: { label: "Number of HVAC Units", min: 1 },
                  maintenanceVisitsPerYear: { label: "Maintenance Visits/Year", min: 0, step: 0.5 },
                  scheduledVisitCost: { label: "Cost per Visit ($)", min: 0 },
                  emergencyCallsPerYear: { label: "Emergency Calls/Year", min: 0 },
                  emergencyCallCost: { label: "Emergency Call Cost ($)", min: 0 },
                  annualPartsCost: { label: "Annual Parts Cost ($)", min: 0 },
                  replacementCostPerUnit: { label: "Unit Replacement Cost ($)", min: 0 },
                  expectedLifespan: { label: "Expected Unit Lifespan (years)", min: 1 },
                }).map(([key, config]) => (
                  <div key={key} className="space-y-2">
                    <Label htmlFor={key}>{config.label}</Label>
                    <Input
                      type="number"
                      id={key}
                      name={key}
                      value={inputs[key]}
                      onChange={handleInputChange}
                      min={config.min}
                      step={config.step || 1}
                    />
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          {/* Chart */}
          <Card>
            <CardContent className="pt-6">
              <div className="h-[400px]">
                <div ref={chartRef} style={{ width: "100%", height: "100%", minHeight: "400px" }} />
              </div>
            </CardContent>
          </Card>

          {/* Scenario Cards */}
          {yearlyData.length > 0 &&
            ["expected", "optimistic", "conservative"].map((scenarioName) => (
              <ScenarioCard
                key={scenarioName}
                title={scenarioName.charAt(0).toUpperCase() + scenarioName.slice(1)}
                scenario={scenarioName}
                yearlyData={yearlyData}
                scenarioConfig={scenarios[scenarioName]}
                baseCosts={baseCosts}
                inputs={inputs}
              />
            ))}
          {coolGuardianCosts && (
            <>
              <Card>
                <CardHeader>
                  <CardTitle>Base Annual Costs Breakdown</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="text-sm text-gray-500">Scheduled Maintenance</div>
                      <div className="text-lg font-semibold">{formatCurrency(baseCosts.scheduledMaintenance)}</div>
                      <div className="text-xs text-gray-400">
                        {inputs.numUnits} units × ${inputs.scheduledVisitCost} × {inputs.maintenanceVisitsPerYear}{" "}
                        visits
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-500">Emergency Calls</div>
                      <div className="text-lg font-semibold">{formatCurrency(baseCosts.emergency)}</div>
                      <div className="text-xs text-gray-400">
                        {inputs.emergencyCallsPerYear} calls × ${inputs.emergencyCallCost}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-500">Parts</div>
                      <div className="text-lg font-semibold">{formatCurrency(baseCosts.parts)}</div>
                      <div className="text-xs text-gray-400">${inputs.annualPartsCost} annual cost</div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-500">Unit Replacements</div>
                      <div className="text-lg font-semibold">{formatCurrency(baseCosts.replacement)}</div>
                      <div className="text-xs text-gray-400">
                        {baseCosts.replacementsPerYear.toFixed(1)} units/year × ${inputs.replacementCostPerUnit}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 pt-4 border-t">
                    <div className="text-xl font-bold flex justify-between">
                      <span>Total Annual Base Cost:</span>
                      <span>{formatCurrency(baseCosts.total)}</span>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Cool Guardian Cost Breakdown</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-6">
                    {/* Hardware Section */}
                    <div>
                      <h3 className="text-lg font-semibold mb-2">Hardware Costs (One-Time)</h3>
                      <div className="grid grid-cols-2 gap-2 bg-gray-50 p-4 rounded-lg">
                        <div className="text-sm text-gray-600">Cost per Unit:</div>
                        <div className="text-right">{formatCurrency(coolGuardianCosts.hardware.perUnit)}</div>
                        <div className="text-sm text-gray-600">Number of Units:</div>
                        <div className="text-right">{inputs.numUnits}</div>
                        <div className="text-sm font-semibold">Total Hardware Cost:</div>
                        <div className="text-right font-semibold">
                          {formatCurrency(coolGuardianCosts.hardware.total)}
                        </div>
                      </div>
                    </div>
                    {/* Subscription Section */}
                    <div>
                      <h3 className="text-lg font-semibold mb-2">Annual Subscription</h3>
                      <div className="grid grid-cols-2 gap-2 bg-gray-50 p-4 rounded-lg">
                        <div className="text-sm text-gray-600">Base Monthly Cost per Unit:</div>
                        <div className="text-right">
                          {formatCurrency(coolGuardianCosts.subscription.baseMonthlyPerUnit)}
                        </div>
                        <div className="text-sm text-gray-600">Base Annual Cost:</div>
                        <div className="text-right">{formatCurrency(coolGuardianCosts.subscription.baseAnnual)}</div>
                        <div className="text-sm text-gray-600">Annual Subscription Discount (10%):</div>
                        <div className="text-right text-green-600">
                          -{formatCurrency(coolGuardianCosts.subscription.annualDiscount)}
                        </div>
                        {inputs.numUnits >= 80 && (
                          <>
                            <div className="text-sm text-gray-600">Bulk Discount (30%):</div>
                            <div className="text-right text-green-600">
                              -{formatCurrency(coolGuardianCosts.subscription.bulkDiscount)}
                            </div>
                          </>
                        )}
                        <div className="text-sm font-semibold">Final Annual Subscription:</div>
                        <div className="text-right font-semibold">
                          {formatCurrency(coolGuardianCosts.subscription.final)}
                        </div>
                        <div className="text-sm text-gray-600">Effective Monthly Cost per Unit:</div>
                        <div className="text-right">
                          {formatCurrency(coolGuardianCosts.subscription.effectiveMonthlyPerUnit)}
                        </div>
                      </div>
                    </div>
                    {/* Total Costs */}
                    <div className="border-t pt-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <div className="text-lg font-bold">First Year Total Cost:</div>
                          <div className="text-sm text-gray-600">(Hardware + Annual Subscription)</div>
                        </div>
                        <div className="text-right text-xl font-bold">
                          {formatCurrency(coolGuardianCosts.total.firstYear)}
                        </div>
                        <div>
                          <div className="text-lg font-bold">Annual Recurring Cost:</div>
                          <div className="text-sm text-gray-600">(Subscription Only)</div>
                        </div>
                        <div className="text-right text-xl font-bold">
                          {formatCurrency(coolGuardianCosts.total.recurring)}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Improvement Scenarios</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {Object.entries(scenarios).map(([name, scenario]) => (
                      <div key={name} className="p-4 rounded-lg bg-gray-50">
                        <h3 className="font-semibold capitalize mb-3">{name} Scenario</h3>
                        <div className="space-y-2 text-sm">
                          <div className="flex justify-between">
                            <span>Emergency Call Reduction:</span>
                            <span>{scenario.emergencyCallReduction}%</span>
                          </div>
                          <div className="flex justify-between">
                            <span>Parts Cost Reduction:</span>
                            <span>{scenario.partsReduction}%</span>
                          </div>
                          <div className="flex justify-between">
                            <span>Lifespan Extension:</span>
                            <span>{scenario.lifespanExtensionPercent}%</span>
                          </div>
                          <div className="flex justify-between">
                            <span>Additional Maintenance:</span>
                            <span>{scenario.additionalMaintenanceVisits} visits/year</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </>
          )}
        </div>
      </div>
    </TopBarCalculator>
  );
};

export default ROICalculator;
