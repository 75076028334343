// StatisticCard.tsx
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LucideIcon, ArrowUpIcon, ArrowDownIcon } from "lucide-react";

interface StatisticCardProps {
  title: string;
  value: number;
  unit: string;
  icon: LucideIcon;
  peak?: number;
  showPeak?: boolean;
  comparison?: {
    weekly: number;
    monthly: number;
  };
}

const ComparisonIndicator = ({ value, period }: { value: number; period: string }) => {
  const isPositive = value > 0;
  const ComparisonIcon = isPositive ? ArrowUpIcon : ArrowDownIcon;

  return (
    <p className="text-xs tracking-wide flex items-center gap-1">
      <div
        className={`border rounded-full p-[1px] ${
          isPositive ? "border-cyan-vivid/50 bg-cyan-vivid/10" : "border-red-brigth/50 bg-red-brigth/10"
        }`}
      >
        <ComparisonIcon className={`h-3 w-3 ${isPositive ? "text-cyan-vivid" : "text-red-brigth"}`} />
      </div>
      <span>
        {Math.abs(value).toFixed(1)}% vs {period} avg
      </span>
    </p>
  );
};

export const StatisticCard = ({ title, value, unit, icon: Icon, peak, showPeak, comparison }: StatisticCardProps) => {
  return (
    <Card>
      <CardHeader className="pb-2">
        <div className="grid grid-cols-[1fr_auto] items-start gap-2">
          <CardTitle className="text-sm font-medium">{title}</CardTitle>
          <Icon className="h-4 w-4 text-muted-foreground" />
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-1.5">
          <div className="text-2xl font-bold">
            {typeof value === "number" ? value.toFixed(1) : value} {unit}
          </div>
          {comparison && (
            <div className="space-y-1">
              <ComparisonIndicator value={comparison.weekly} period="7d" />
              <ComparisonIndicator value={comparison.monthly} period="30d" />
            </div>
          )}
          {showPeak && (
            <div className="text-xs text-muted-foreground">
              Peak: {peak?.toFixed(1)} {unit}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
