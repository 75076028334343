import { create } from "zustand";
import { Subject, merge, Observable } from "rxjs";
import { map, tap, share } from "rxjs/operators";
import { getStreamFromWS } from "@/features/dashboard/echart/getStreamFromWS";

type SensorData = Record<string, { time: number; value: number }[]>;
type StreamType = "lowLine" | "highLine" | "currents";

interface StreamContainer {
  data$: Observable<SensorData>;
  subject: Subject<SensorData>;
  ws: WebSocket;
  lastUpdate$: Observable<Date>;
}

type SensorState = {
  lastSensorUpdate: { [sensorType: string]: Date };
  streams: Partial<Record<StreamType, StreamContainer>>;
  all$: Observable<SensorData> | null;
  kernelId: string | null;
  isLoading: boolean;
  receivedData: Record<StreamType, boolean>;
};

type SensorActions = {
  initializeStreams: (kernelId: string) => void;
  cleanupStreams: () => void;
  setIsLoading: (loading: boolean) => void;
};

const createStreamContainer = (kernelId: string, streamType: string, onDataReceived: () => void): StreamContainer => {
  const { subject, ws } = getStreamFromWS(kernelId, streamType);

  // Create an enhanced data stream that tracks its own timestamps
  const data$ = subject.pipe(
    tap(() => onDataReceived()),
    share()
  );

  // Create a stream specifically for timestamp updates
  const lastUpdate$ = data$.pipe(
    map((data) => {
      const lastUpdateTime = Math.max(...Object.values(data).map((arr) => arr[arr.length - 1]?.time || 0));
      return new Date(lastUpdateTime);
    }),
    share()
  );

  return {
    data$,
    subject,
    ws,
    lastUpdate$,
  };
};

export const useSensorStore = create<SensorState & SensorActions>((set, get) => ({
  lastSensorUpdate: {},
  streams: {},
  all$: null,
  kernelId: null,
  isLoading: true,
  receivedData: {
    lowLine: false,
    highLine: false,
    currents: false,
  },

  setIsLoading: (loading) => set({ isLoading: loading }),

  initializeStreams: (kernelId: string) => {
    if (get().kernelId === kernelId) return;

    // Cleanup existing streams
    if (get().kernelId) {
      get().cleanupStreams();
    }

    // Reset state
    set({
      isLoading: true,
      receivedData: {
        lowLine: false,
        highLine: false,
        currents: false,
      },
      lastSensorUpdate: {},
    });

    // Create stream containers with update tracking
    const createStream = (type: StreamType) => {
      return createStreamContainer(
        kernelId,
        type.replace(/([A-Z])/g, "-$1").toLowerCase(), // Convert camelCase to kebab-case
        () =>
          set((state) => ({
            receivedData: {
              ...state.receivedData,
              [type]: true,
            },
            isLoading: !Object.values({
              ...state.receivedData,
              [type]: true,
            }).every(Boolean),
          }))
      );
    };

    const streams: Record<StreamType, StreamContainer> = {
      lowLine: createStream("lowLine"),
      highLine: createStream("highLine"),
      currents: createStream("currents"),
    };

    // Set up timestamp subscriptions
    Object.entries(streams).forEach(([type, container]) => {
      container.lastUpdate$.subscribe((date) => {
        set((state) => ({
          lastSensorUpdate: { ...state.lastSensorUpdate, [type]: date },
        }));
      });
    });

    // Create combined data stream
    const all$ = merge(...Object.values(streams).map((container) => container.data$)).pipe(share());

    set({
      kernelId,
      streams,
      all$,
    });
  },

  cleanupStreams: () => {
    const { streams } = get();

    // Close all websockets
    Object.values(streams).forEach((container) => {
      if (container?.ws.readyState === WebSocket.OPEN) {
        container.ws.close();
      }
    });

    // Reset state
    set({
      streams: {},
      all$: null,
      kernelId: null,
      lastSensorUpdate: {},
      receivedData: {
        lowLine: false,
        highLine: false,
        currents: false,
      },
    });
  },
}));

// Helper hooks for consuming the streams
export const useSensorStream = (streamType: StreamType) => {
  return useSensorStore((state) => state.streams[streamType]?.data$ ?? null);
};

export const useLastSensorUpdate = (streamType: StreamType) => {
  return useSensorStore((state) => state.lastSensorUpdate[streamType]);
};

export const useAllSensorStreams = () => {
  return useSensorStore((state) => state.all$);
};
