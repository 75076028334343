import { create, StoreApi, UseBoundStore } from "zustand";

import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import { httpApi } from "@/http-api";

loadStripe(
  "pk_test_51PK2kWKIN72O6H31IVFRWNsxORL4PZtLCSo6uibsQyVvTDKXDur6SekMcx01bCapHFYIS4aiOfvF2KCe7q96xm3800j1HQ6cWL"
).then((stripe) => {
  console.log("Stripe loaded", stripe);
  usePaymentStore.getState().setStripe(stripe);
});

export type BundlePrice = {
  oneTime?: Price;
  recurring: Price;
};

export type Price = {
  id: string;
  unitAmount: number;
  nickname: string;
  recurring?: { deviceId; recurringPriceId; oneTimePriceId; interval: "month" | "year"; intervalCount: number };
  metadata: { description: string | undefined; features: string | undefined } | undefined;
};

interface PaymentOption {
  selectedPlan: string;
  selectedPrice: number;
  selectedPlanDescription: string;
  totalPrice: number;
  oneTimePriceId: string;
  priceOneTime: number;
  featureSelected: string[];
}
export type PaymentStoreState = {
  clientSecret?: string;
  stripe?: Stripe;
  elements?: StripeElements;
  prices?: BundlePrice[];
  // TODO: set this when we create a payment intent
  // use it in the success page
  selectedPrice?: BundlePrice;
  selectedPaymentOption: PaymentOption;
};

export type PaymentStoreActions = {
  getPrices: () => Promise<void>;
  createPaymentIntent: (deviceId: string, priceId: string, customerId: string) => Promise<void>;
  initiatePayment: () => Promise<void>;
  setStripe: (stripe: Stripe) => void;
  setElements: (elements: StripeElements) => void;
  setSelectedPaymentOption: (
    plan: string,
    price: number,
    planDescription: string,
    totalPrice: number,
    oneTimePriceId: string,
    priceOneTime: number,
    features: string[]
  ) => void;
};

export type PaymentStore = PaymentStoreState & PaymentStoreActions;

export const usePaymentStore: UseBoundStore<StoreApi<PaymentStore>> = create<PaymentStore>((set, get) => ({
  selectedPaymentOption: {
    selectedPlan: "",
    selectedPrice: 0,
    selectedPlanDescription: "",
    totalPrice: 0,
    oneTimePriceId: "",
    priceOneTime: 200,
    featureSelected: [],
  },
  setSelectedPaymentOption: (plan, price, planDescription, totalPrice, oneTimePriceId, priceOneTime, features) =>
    set({
      selectedPaymentOption: {
        selectedPlan: plan,
        selectedPrice: price,
        selectedPlanDescription: planDescription,
        totalPrice: totalPrice,
        oneTimePriceId: oneTimePriceId,
        priceOneTime: priceOneTime,
        featureSelected: features,
      },
    }),
  setStripe: (stripe) => set({ stripe }),
  setElements: (elements) => set({ elements }),
  async getPrices() {
    // if stripe is not set, return
    if (!get().stripe) {
      throw new Error("Stripe is not set");
    }
    const { data: prices } = await httpApi.getPrices();
    console.log("Prices", prices);

    return set({ prices });
  },
  async createPaymentIntent(deviceId: string, recurringPriceId: string, oneTimePriceId: string) {
    // if stripe is not set, return
    if (!get().stripe) {
      throw new Error("Stripe is not set");
    }
    const { data } = await httpApi.createPaymentIntent(deviceId, recurringPriceId, oneTimePriceId);

    console.log("Payment intent", data);
    console.log("Client secret", data.latestInvoice.paymentIntent.clientSecret);
    return set({ clientSecret: data.latestInvoice.paymentIntent.clientSecret });
  },
  async initiatePayment() {
    console.log("Initiating payment");
    // if stripe is not set, return
    if (!get().stripe) {
      throw new Error("Stripe is not set");
    }
    if (!get().clientSecret) {
      throw new Error("Client secret is not set");
    }

    const elements = get().elements;

    const returnUrl = new URL(location.href);
    returnUrl.search = "";
    returnUrl.pathname = "/payment/success";

    const confirmPaymentResult = await get().stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl.toString(),
      },
    });

    console.log("Confirm payment result", confirmPaymentResult);

    if (confirmPaymentResult.error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.error(confirmPaymentResult.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  },
}));
