import { create } from "zustand";
import { Subject, Observable, interval } from "rxjs";
import { map, tap, share, startWith, shareReplay } from "rxjs/operators";
import { getStreamFromWS } from "@/features/dashboard/echart/getStreamFromWS";
import { StatsData, TimeFrame } from "./types";

// Mock data generator for testing
const createMockData = () => {
  const now = Date.now();
  const createTimeSeriesValue = (value: number) => [
    {
      time: now,
      value: value,
    },
  ];

  return {
    // 24h values
    cycles_24h: createTimeSeriesValue(24),
    consumption_24h: createTimeSeriesValue(45.2),
    longest_runtime_24h: createTimeSeriesValue(120),
    shortest_runtime_24h: createTimeSeriesValue(45),
    fan_current_peak_24h: createTimeSeriesValue(2.8),
    fan_current_avg_24h: createTimeSeriesValue(1.9),
    compressor_current_peak_24h: createTimeSeriesValue(8.5),
    compressor_current_avg_24h: createTimeSeriesValue(6.2),
    blower_current_peak_24h: createTimeSeriesValue(1.8),
    blower_current_avg_24h: createTimeSeriesValue(1.2),

    // 7d values (slightly different for comparison)
    cycles_7d: createTimeSeriesValue(22),
    consumption_7d: createTimeSeriesValue(42.8),
    longest_runtime_7d: createTimeSeriesValue(115),
    shortest_runtime_7d: createTimeSeriesValue(42),
    fan_current_peak_7d: createTimeSeriesValue(2.6),
    fan_current_avg_7d: createTimeSeriesValue(1.7),
    compressor_current_peak_7d: createTimeSeriesValue(8.2),
    compressor_current_avg_7d: createTimeSeriesValue(5.9),
    blower_current_peak_7d: createTimeSeriesValue(1.7),
    blower_current_avg_7d: createTimeSeriesValue(1.1),

    // 30d values
    cycles_30d: createTimeSeriesValue(21),
    consumption_30d: createTimeSeriesValue(41.5),
    longest_runtime_30d: createTimeSeriesValue(110),
    shortest_runtime_30d: createTimeSeriesValue(40),
    fan_current_peak_30d: createTimeSeriesValue(2.5),
    fan_current_avg_30d: createTimeSeriesValue(1.6),
    compressor_current_peak_30d: createTimeSeriesValue(8.0),
    compressor_current_avg_30d: createTimeSeriesValue(5.7),
    blower_current_peak_30d: createTimeSeriesValue(1.6),
    blower_current_avg_30d: createTimeSeriesValue(1.0),
  };
};

interface StatsState {
  stats: Record<TimeFrame, StatsData | null>;
  stream: {
    data$: Observable<Record<string, StatsData>> | null;
    subject: Subject<Record<string, { time: number; value: number }[]>> | null;
    ws: WebSocket | null;
  };
  isLoading: boolean;
  selectedTimeFrame: TimeFrame;
}

interface StatsActions {
  initializeStream: (kernelId: string) => void;
  cleanupStream: () => void;
  setTimeFrame: (timeFrame: TimeFrame) => void;
}

const processTimeSeriesData = (
  data: Record<string, { time: number; value: number }[]>
): Record<TimeFrame, StatsData> => {
  console.log("Processing time series data", data);
  const result: Partial<Record<TimeFrame, StatsData>> = {};
  const timeFrames: TimeFrame[] = ["24h", "7d", "30d"];

  timeFrames.forEach((timeFrame) => {
    const getValue = (prefix: string) => {
      const key = `${prefix}_${timeFrame}`;
      return data[key]?.[0]?.value ?? 0;
    };

    result[timeFrame] = {
      cyclesCount: getValue("cycles"),
      consumption: getValue("consumption"),
      longestRunTime: getValue("longest_runtime"),
      shortestRunTime: getValue("shortest_runtime"),
      maxPeakCurrentFan: getValue("fan_current_peak"),
      avgRunningCurrentFan: getValue("fan_current_avg"),
      maxPeakCurrentCompressor: getValue("compressor_current_peak"),
      avgRunningCurrentCompressor: getValue("compressor_current_avg"),
      maxPeakCurrentBlower: data[`blower_current_peak_${timeFrame}`] ? getValue("blower_current_peak") : undefined,
      avgRunningCurrentBlower: data[`blower_current_avg_${timeFrame}`] ? getValue("blower_current_avg") : undefined,
    };
  });

  return result as Record<TimeFrame, StatsData>;
};

export const useStatsStore = create<StatsState & StatsActions>((set) => ({
  stats: {
    "24h": null,
    "7d": null,
    "30d": null,
  },
  stream: {
    data$: null,
    subject: null,
    ws: null,
  },
  isLoading: true,
  selectedTimeFrame: "24h",

  setTimeFrame: (timeFrame) => set({ selectedTimeFrame: timeFrame }),

  initializeStream: (kernelId: string) => {
    const { subject, ws } = getStreamFromWS(kernelId, "stats");
    // Instead of using getStreamFromWS, we'll create a mock subject
    const testSubject$ = new Subject<Record<string, { time: number; value: number }[]>>();

    const testData$ = testSubject$.pipe(
      map(processTimeSeriesData),
      tap((processedData) => {
        set((state) => ({
          stats: processedData,
          isLoading: false,
        }));
      }),
      shareReplay(1)
    );

    testData$.subscribe((data) => {
      console.log("Processed data", data);
    });

    /*const data$ = subject.pipe(
      map(processTimeSeriesData),
      tap((processedData) => {
        set((state) => ({
          stats: processedData,
          isLoading: false,
        }));
      }),
      share()
    );*/
    // Simulate periodic updates
    const mockStream = interval(5000).pipe(
      startWith(0),
      map(() => {
        const mockData = createMockData();
        // Randomly vary some values slightly for realism
        mockData.cycles_24h[0].value += Math.random() * 2 - 1;
        mockData.consumption_24h[0].value += Math.random() * 0.5 - 0.25;
        mockData.fan_current_avg_24h[0].value += Math.random() * 0.2 - 0.1;
        mockData.compressor_current_avg_24h[0].value += Math.random() * 0.3 - 0.15;
        return mockData;
      }),
      share()
    );

    // Subscribe to mock stream and feed into subject
    mockStream.subscribe((data) => {
      testSubject$.next(data);
    });

    //set({ stream: { data$, subject, ws } });
    set({ stream: { data$: testData$, subject: testSubject$, ws } });
  },

  cleanupStream: () => {
    const { ws } = useStatsStore.getState().stream;
    if (ws?.readyState === WebSocket.OPEN) {
      ws.close();
    }
    set({
      stream: { data$: null, subject: null, ws: null },
      stats: { "24h": null, "7d": null, "30d": null },
      isLoading: true,
    });
  },
}));
