import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import * as echarts from "echarts/core";
import { BarChart, BarSeriesOption, GaugeChart, LineChart, GaugeSeriesOption, LineSeriesOption } from "echarts/charts";
import {
  TitleComponent,
  TitleComponentOption,
  GridComponent,
  GridComponentOption,
  DatasetComponent,
  DatasetComponentOption,
  TransformComponent,
  TooltipComponent,
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { DataZoomComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { Subject } from "rxjs";

export type ECOption = echarts.ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | GridComponentOption
  | DatasetComponentOption
  | GaugeSeriesOption
>;

echarts.use([
  DataZoomComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  GaugeChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

export const EChartsStreamVisualizer: React.FC<{
  options: ECOption;
  data$?: Subject<Record<string, { time: number; value: number }[]>>;
  clear$?: Subject<void>;
  minHeight?: string;
  minWidth?: string;
}> = ({ options, data$, clear$, minHeight, minWidth }) => {
  //console.log("Data$ EChartsStreamVisualizer", data$);
  const chartRef = useRef<HTMLDivElement>(null);
  const [echartsInstance, setEchartsInstance] = useState<echarts.ECharts>();

  const dataRef = useRef<
    Record<string, number[][] | { name: string; type: "gauge"; detail: any; data: { name: string; value: number }[] }>
  >({});
  const [windowSize, setWindowSize] = useState<number>(50000000);

  useLayoutEffect(() => {
    const echartsInstance = echarts.init(chartRef.current as HTMLDivElement);
    setEchartsInstance(echartsInstance);
    // initial option
    echartsInstance.setOption(options);
    echartsInstance.showLoading({
      text: "",
      maskColor: "rgba(255, 255, 255, 1)",
      spinnerRadius: 16,
    });

    // clear data when clear$ emits
    clear$?.subscribe(() => {
      for (const seriesName of Object.keys(dataRef.current)) {
        dataRef.current[seriesName] = [];
      }
      echartsInstance.setOption({
        series: Object.entries(dataRef.current).map(([name, data]) => ({
          name,
          type: Array.isArray(options.series) ? options.series.find((s) => s.name === name)?.type : "line",
          data,
        })),
      });
    });

    // example device output
    // const deviceOutput = {
    //     "pressureLow": [
    //       { time: 1, value: 100 },
    //       { time: 2, value: 101 },
    //       { time: 3, value: 102 },
    //     ],
    //  };

    data$?.subscribe((sensorValues) => {
      // console.log("sensorValues", sensorValues);
      // update time scale range
      const tmax = Object.values(sensorValues).reduce(
        (acc, portValues) => Math.max(acc, ...portValues.map((v) => v.time)),
        -1
      );
      const tmin = tmax - windowSize;

      Array.from(options.series as Array<any>).forEach((originalSeries) => {
        if (originalSeries.type === "gauge") {
          // TODO: maybe we should use a series id instead of relying on the uniqueness of name
          const seriesName = originalSeries.name;
          const data = originalSeries.data.map((d: any) => {
            const sensorId: string = d.sensorId;
            // get the values
            const values = sensorValues[sensorId];
            if (!values) {
              console.error(`sensorValues[${sensorId}] is undefined`);
              return;
            }
            // get the last value in the list
            const lastValue = values[values.length - 1].value;

            if (!lastValue) return;

            return { ...d, value: lastValue.toFixed(0) };
          });

          dataRef.current[seriesName] = data;
        } else {
          const seriesName = originalSeries.name;
          //console.log("seriesName", seriesName);
          if (!seriesName) {
            console.error("seriesName is undefined");
            return;
          }
          if (!dataRef.current[seriesName]) dataRef.current[seriesName] = [];
          const entry = sensorValues[seriesName];
          if (!entry) {
            //console.error(`entry for ${seriesName} is undefined`);
            return;
          }
          dataRef.current[seriesName] = [
            ...(dataRef.current[seriesName] as number[][]),
            ...entry.map(({ time, value }) => (!isNaN(time) && !isNaN(value) ? [time, value] : [])),
          ];

          // remove old data
          dataRef.current[seriesName] = (dataRef.current[seriesName] as number[][]).filter(([t0]) => t0 >= tmin);
          // remove duplicates, keep the last one
          dataRef.current[seriesName] = (dataRef.current[seriesName] as number[][]).reduce((acc, [time, value]) => {
            if (acc.length === 0 || acc[acc.length - 1][0] !== time) {
              acc.push([time, value]);
            } else {
              acc[acc.length - 1][1] = value;
            }
            return acc;
          }, [] as number[][]);
          // sort by time
          (dataRef.current[seriesName] as number[][]).sort((a, b) => a[0] - b[0]);
          // show a maximum of 20 points
          //dataRef.current[seriesName] = (dataRef.current[seriesName] as number[][]).slice(-20);
        }
      });

      echartsInstance.setOption({
        series: Object.entries(dataRef.current).map(([name, data]) => ({
          name,
          data,
        })),
      });
      echartsInstance.hideLoading();
    });
  }, []);

  useEffect(() => {
    //console.log("echart resize");

    // resize the chart when the parent div is resized
    // using a resize observer
    const resizeObserver = new ResizeObserver(() => {
      echartsInstance?.resize();
    });
    resizeObserver.observe(chartRef.current as HTMLDivElement);
    return () => {
      resizeObserver.disconnect();
    };
  }, [echartsInstance]);

  return <div style={{ width: "100%", height: "100%", minHeight, objectFit: "contain", minWidth }} ref={chartRef} />;
};
