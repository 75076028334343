import { SignInForm } from "./SignInForm";
import { useNavigate } from "react-router-dom";
import { AuthLayout } from "../layout/AuthLayout";

const SignIn = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <div className="p-2 sm:p-16 md:p-2 lg:p-12 xl:p-20 space-y-4">
        <div className="flex flex-row pt-12 lg:pt-0 justify-center text-center">
          <h1 className="text-lg md:text-xl font-semibold tracking-tight text-foreground">
            Welcome back to Cool-Guardian!
          </h1>
        </div>
        <div className="flex justify-center text-sm font-medium space-x-1 pb-4">
          <h2 className="text-muted-foreground">Don't have an account?</h2>
          <label
            className="underline peer-disabled:cursor-allowed peer-disabled:opacity-70 float-right cursor-pointer text-foreground leading-tight"
            onClick={() => navigate("/auth/signup")}
          >
            Create an account
          </label>
        </div>
        <SignInForm />
      </div>
    </AuthLayout>
  );
};

export default SignIn;
