import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Check, Download } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { usePaymentStore } from "../payment-store";
import LayoutPayment from "../layout/LayoutPayment";
import { useCallback, useEffect, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import type { CreateTypes } from "canvas-confetti";

export default function SuccessPaymentPage() {
  const navigate = useNavigate();
  const { selectedPaymentOption } = usePaymentStore();
  const refAnimationInstance = useRef<CreateTypes | null>(null);

  const getInstance = useCallback((instance: CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio: number, opts: object) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    fire();
  }, [fire]);

  return (
    <LayoutPayment>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{
          position: "fixed",
          pointerEvents: "none",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: 100,
        }}
      />
      <div className="flex justify-center">
        <div className="container max-w-screen-md px-4 md:px-8 items-center bg-transparent dark:bg-transparent">
          <div className="flex flex-col w-full justify-center mt-28 sm:mt-40">
            <div className="p-6 shadow-xl shadow-cyan-pale bg-cyan-vivid/10 rounded-xl border border-[1px]">
              <div className="space-y-4">
                <div className="flex justify-center">
                  <div className="relative bg-cyan-mostwhite p-2 rounded-full z-10">
                    <div className="bg-cyan-pale p-4 rounded-full">
                      <div className="bg-primary p-2 rounded-full">
                        <Check className="h-6 w-6 text-white" />
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="font-bold text-2xl sm:text-4xl text-center text-strong-cyan">Payment Success</h2>
                <p className="text-lg font-semibold text-center">${selectedPaymentOption.totalPrice}</p>
                <p className="text-md text-center text-muted-foreground">
                  Thank you for your payment. Your transaction has been completed successfully.
                </p>
              </div>
              <div className="flex flex-col my-4">
                <Separator className="my-4" />
                <div className="divide-y divide-dashed divide-slate-400/25">
                  <div className="grid grid-cols-2 p-2">
                    <div className="text-muted-foreground space-y-2">
                      <p>Ref Number</p>
                      <p>Payment Time</p>
                      <p>Payment Method</p>
                      <p>Sender Name</p>
                    </div>
                    <div className="text-end justify-self-end space-y-2">
                      <p>123456789</p>
                      <p>{new Date().toLocaleString()}</p>
                      <p>Credit Card</p>
                      <p>John Doe</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 p-2">
                    <div className="text-muted-foreground space-y-2">
                      <p>Amount</p>
                      <p>Admin Fee</p>
                      <p>Payment Status</p>
                    </div>
                    <div className="justify-self-end space-y-2 text-end">
                      <p>${selectedPaymentOption.selectedPrice}</p>
                      <p>$0</p>
                      <p>Completed</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center mt-4 space-y-4 sm:space-y-0 sm:space-x-4">
              <Button
                variant="outline"
                className="w-full sm:w-auto rounded-3xl"
                onClick={() => {
                  // Implement PDF receipt download logic here
                }}
              >
                <Download className="mr-2" />
                Get PDF Receipt
              </Button>
              <Button
                className="w-full sm:w-auto rounded-3xl"
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Go to your dashboard
              </Button>
            </div>
          </div>
        </div>
      </div>
    </LayoutPayment>
  );
}
