import { ModeToggle } from "@/components/ui/mode-toggle";
import { UserMenu } from "../header/UserMenu";
import { ModeNotify } from "@/components/ui/mode-notify";
import { ModeContact } from "@/components/ui/mode-contact";
import WhiteLogo from "@/assets/svg/Color-logo-no-background.svg";
import DarkLogo from "@/assets/Logo-DarkMode.png";
import { useTheme } from "@/components/ui/theme-provider";
import { useNavigate } from "react-router-dom";

export const TopBar = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  const showLogo = () => {
    if (theme === "dark") {
      return <img src={DarkLogo} alt="DarkLogo" className="h-10 object-contain"></img>;
    } else {
      return <img src={WhiteLogo} alt="WhiteLogo" className="h-10 object-contain"></img>;
    }
  };

  return (
    <header className="border border-input sm:h-auto sm:border-0">
      <div className="flex h-16 w-screen sm:w-full justify-between items-center bg-cyan-verydark/70 shadow-none backdrop-blur px-4 sm:px-6">
        <div className="flex items-center hover:cursor-pointer" onClick={() => navigate("/")}>
          {showLogo()}
        </div>
        <div className="hidden ml-auto sm:flex items-center gap-2 mr-2">
          <ModeContact />
          <ModeNotify />
          <ModeToggle />
        </div>
        <UserMenu />
      </div>
    </header>
  );
};
