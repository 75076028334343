import React, { useEffect, useCallback } from "react";
import { useAuthStore } from "@/features/auth/auth-store";
import { useDashboardStore } from "@/features/dashboard/dashboard-store";
import { useSensorStore } from "@/features/dashboard/sensor-store";
import { TopBar } from "@/features/dashboard/header/TopBar";
import { WelcomeUser } from "@/features/dashboard/welcome-user/welcomeUser";
import { OverallStatus } from "@/features/dashboard/status/OverallStatus";
import { DashboardDrawer } from "./DashboardDrawer";
import { DeviceHistoricalData } from "./DeviceHistoricalData";
import { DeviceStatistics } from "./DeviceStatistics";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SystemStatusCard } from "./SystemStatusCard";
import { DeviceInfoCard } from "./DeviceInfoCard";
import { Button } from "@/components/ui/button";
import { LoadingOverlay } from "./LoadingOverlay";

// Separate the device selection logic into its own component
const DeviceContent = ({ selectedDevice }) => {
  const navigate = useNavigate();
  if (!selectedDevice) {
    return (
      <div className="flex flex-col items-center justify-center h-[400px] bg-muted/10 rounded-lg">
        <h3 className="text-2xl text-foreground dark:text-blue-brand">Select a device to view analytics</h3>
      </div>
    );
  }

  return (
    <div className="min-w-screen-xl">
      <div className="flex min-w-xl justify-between items-center pb-3">
        <h3 className="text-3xl font-bold tracking-tight">
          {selectedDevice.monitoring?.brand || "Device"} {selectedDevice.subscribe ? "Analytics" : ""}
        </h3>
      </div>

      {selectedDevice.subscribed ? (
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-6">
            <SystemStatusCard device={selectedDevice} />
            <DeviceInfoCard device={selectedDevice} />
          </div>
          <DeviceStatistics />
          <DeviceHistoricalData />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-[400px] bg-muted/10 rounded-lg">
          <div className="min-w-lg w-full pb-6">
            <DeviceInfoCard device={selectedDevice} />
          </div>
          <h3 className="text-2xl text-foreground dark:text-blue-brand">No active subscription</h3>
          <p className="text-base text-muted-foreground dark:text-blue-brand py-6">
            Please subscribe to a plan to view device analytics
          </p>
          <Button onClick={() => navigate(`/payment/subscription-options?deviceId=${selectedDevice.deviceId}`)}>
            Subscribe Now
          </Button>
        </div>
      )}
    </div>
  );
};

// Separate the status components into their own component
const StatusSection = ({ isNewUser, status }) => (
  <>
    {isNewUser && <WelcomeUser />}
    {status && <OverallStatus status={status} />}
  </>
);

export const DashboardPage = () => {
  const [searchParams] = useSearchParams();
  const deviceId = searchParams.get("deviceId");

  // Split state selections into smaller, focused selectors
  const isNewUser = useAuthStore(useCallback((state) => state.isNewUser, []));

  const { status, selectedDevice, devices } = useDashboardStore(
    useCallback(
      (state) => ({
        status: state.status,
        selectedDevice: state.selectedDevice,
        devices: state.devices,
      }),
      []
    )
  );

  const { loadDevices, selectDevice, setDrawerOpen } = useDashboardStore(
    useCallback(
      (state) => ({
        loadDevices: state.loadDevices,
        selectDevice: state.selectDevice,
        setDrawerOpen: state.setDrawerOpen,
      }),
      []
    )
  );

  // Handle device selection
  useEffect(() => {
    loadDevices().then(() => {
      if (deviceId) {
        console.log("Selecting device", deviceId);
        selectDevice(deviceId);
        return;
      }
      if (devices.length === 1) {
        console.log("Auto-selecting device", devices[0].deviceId);
        selectDevice(devices[0].deviceId);
        return;
      }
      setDrawerOpen(true);
    });
  }, [deviceId]);

  const isLoading = useSensorStore((state) => state.isLoading);

  return (
    <div className="min-h-screen">
      <div className="fixed top-0 w-full z-30 shadow-md">
        <TopBar />
      </div>

      <DashboardDrawer />
      {selectedDevice?.subscribed && isLoading && <LoadingOverlay />}

      <main className="flex justify-center transition-all duration-300">
        <div className="max-w-screen-2xl py-6 mt-16">
          <div className="space-y-6">
            <StatusSection isNewUser={isNewUser} status={status} />
            <DeviceContent selectedDevice={selectedDevice} />
          </div>
        </div>
      </main>
    </div>
  );
};
