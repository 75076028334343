export enum Refrigerant {
  R410A = "R410A",
  R22 = "R22",
  R134A = "R134A",
  UNKNOWN = "UNKNOWN",
}

export const SaturationCurve = {
  [Refrigerant.R410A]: [
    // F, PSIG
    [12, -37.7],
    [14, -34.7],
    [16, -32.0],
    [18, -29.4],
    [20, -36.9],
    [22, -24.5],
    [24, -22.2],
    [26, -20.0],
    [28, -17.9],
    [30, -15.8],
    [32, -13.8],
    [34, -11.9],
    [36, -10.1],
    [38, -8.3],
    [40, -6.5],
    [42, -4.5],
    [44, -3.2],
    [46, -1.6],
    [48, 0],
    [50, 1.5],
    [52, 3],
    [54, 4.5],
    [56, 5.9],
    [58, 7.3],
    [60, 8.6],
    [62, 10],
    [64, 11.3],
    [66, 12.6],
    [68, 13.8],
    [70, 15.1],
    [72, 16.3],
    [74, 17.5],
    [76, 18.7],
    [78, 19.8],
    [80, 21],
    [82, 22.1],
    [84, 23.2],
    [86, 24.3],
    [88, 25.4],
    [90, 26.4],
    [92, 27.4],
    [94, 28.5],
    [96, 29.5],
    [98, 30.5],
    [100, 31.2],
    [102, 32.2],
    [104, 33.2],
    [106, 34.1],
    [108, 35.1],
    [110, 35.5],
    [112, 36.9],
    [114, 37.8],
    [116, 38.7],
    [118, 39.5],
    [120, 40.5],
    [122, 41.3],
    [124, 42.2],
    [126, 43],
    [128, 43.8],
    [130, 44.7],
    [132, 45.5],
    [134, 46.3],
    [136, 47.1],
    [138, 47.9],
    [140, 48.7],
    [142, 49.5],
    [144, 50.3],
    [146, 51.1],
    [148, 51.8],
    [150, 52.5],
    [152, 53.3],
    [154, 54],
    [156, 54.8],
    [158, 55.5],
    [160, 56.2],
    [162, 57],
    [164, 57.7],
    [166, 58.4],
    [168, 59],
    [170, 59.8],
    [172, 60.5],
    [174, 61.1],
    [176, 61.8],
    [178, 62.5],
    [180, 63.1],
    [182, 63.8],
    [184, 64.5],
    [186, 65.1],
    [188, 65.8],
    [190, 66.4],
    [192, 67],
    [194, 67.7],
    [196, 68.3],
    [198, 68.9],
    [200, 69.5],
    [202, 70.1],
    [204, 70.7],
    [206, 71.4],
    [208, 72],
    [210, 72.6],
    [212, 73.2],
    [214, 73.8],
    [216, 74.3],
    [218, 74.9],
    [220, 75.5],
    [222, 76.1],
    [224, 76.7],
    [226, 77.2],
    [228, 77.8],
    [230, 78.4],
    [232, 78.9],
    [234, 79.5],
    [236, 80],
    [238, 80.6],
    [240, 81.1],
    [242, 81.6],
    [244, 82.2],
    [246, 82.7],
    [248, 83.3],
    [250, 83.8],
    [252, 84.3],
    [254, 84.8],
    [256, 85.4],
    [258, 85.9],
    [260, 86.4],
    [262, 86.9],
    [264, 87.4],
    [266, 87.9],
    [268, 88.4],
    [270, 88.9],
    [272, 89.4],
    [274, 89.9],
    [276, 90.4],
    [278, 90.9],
    [280, 91.4],
    [282, 91.9],
    [284, 92.4],
    [286, 92.8],
    [288, 93.3],
    [290, 93.8],
    [292, 94.3],
    [294, 94.8],
    [296, 95.2],
    [298, 95.7],
    [300, 96.2],
    [302, 96.6],
    [304, 97.1],
    [306, 97.5],
    [308, 98],
    [310, 98.4],
    [312, 98.9],
    [314, 99.3],
    [316, 99.7],
    [318, 100.2],
    [320, 100.7],
    [322, 101.1],
    [324, 101.6],
    [326, 102],
    [328, 102.4],
    [330, 102.9],
    [332, 103.3],
    [334, 103.7],
    [336, 104.2],
    [338, 104.6],
    [340, 105.1],
    [342, 105.4],
    [344, 105.8],
    [346, 106.3],
    [348, 106.6],
    [350, 107.1],
    [352, 107.5],
    [354, 107.9],
    [356, 108.3],
    [358, 108.8],
    [360, 109.2],
    [362, 109.6],
    [364, 110],
    [366, 110.4],
    [368, 110.8],
    [370, 111.2],
    [372, 111.6],
    [374, 112],
    [376, 112.4],
    [378, 112.6],
    [380, 113.1],
    [382, 113.5],
    [384, 113.9],
    [386, 114.3],
    [388, 114.7],
    [390, 115],
    [392, 115.5],
    [394, 115.8],
    [396, 116.2],
    [398, 116.6],
    [400, 117],
    [402, 117.3],
    [404, 117.7],
    [406, 118.1],
    [408, 118.5],
    [410, 118.8],
    [412, 119.2],
    [414, 119.6],
    [416, 119.9],
    [418, 120.3],
    [420, 120.7],
    [422, 121],
    [424, 121.4],
    [426, 121.7],
    [428, 122.1],
    [430, 122.5],
    [432, 122.8],
    [434, 123.2],
    [436, 123.5],
    [438, 123.9],
    [440, 124.2],
    [442, 124.6],
    [444, 124.9],
    [446, 125.3],
    [448, 125.6],
    [450, 126],
    [452, 126.3],
    [454, 126.6],
    [456, 127],
    [458, 127.3],
    [460, 127.7],
    [462, 128],
    [464, 128.3],
    [466, 128.7],
    [468, 129],
    [470, 129.3],
    [472, 129.7],
    [474, 130],
    [476, 130.3],
    [478, 130.7],
    [480, 131],
    [482, 131.3],
    [484, 131.6],
    [486, 132],
    [488, 132.3],
    [490, 132.6],
    [492, 132.9],
    [494, 133.3],
    [496, 133.6],
    [498, 133.9],
    [500, 134],
    [502, 134.5],
    [504, 134.8],
    [506, 135.2],
    [508, 135.5],
    [510, 135.8],
    [512, 136.1],
    [514, 136.4],
    [516, 136.7],
    [518, 137],
    [520, 137.3],
    [522, 137.6],
    [524, 137.9],
    [526, 138.3],
    [528, 138.6],
    [530, 138.9],
    [532, 139.2],
    [534, 139.5],
    [536, 139.8],
    [538, 140.1],
    [540, 140.4],
    [544, 141],
    [548, 141.6],
    [552, 142.1],
    [556, 142.7],
    [560, 143.3],
    [564, 143.9],
    [568, 144.5],
    [572, 145],
    [576, 145.6],
    [580, 146.2],
    [584, 146.7],
    [588, 147.3],
    [592, 147.9],
    [596, 148.4],
    [600, 149],
    [604, 149.5],
    [608, 150.1],
    [612, 150.6],
    [616, 151.2],
    [620, 151.7],
    [624, 152.3],
    [628, 152.8],
    [632, 153.4],
    [636, 153.9],
    [640, 154.5],
    [644, 155],
    [648, 155.5],
    [652, 156.1],
    [656, 156.6],
    [660, 157.1],
    [664, 157.7],
    [668, 158.2],
    [672, 158.7],
    [676, 159.2],
    [680, 159.8],
    [684, 160.3],
    [688, 160.8],
    [692, 161.3],
    [696, 161.8],
  ],
};

export const r410aFtoPsi = (F: number) => 0.01876036 * F * F + 0.8885486 * F + 60.11782;
export const r410aPsiToF = (psi: number) => {
  const psig = psi - 14.7;
  // if is beyond the curve then throw an error
  if (
    psi < SaturationCurve[Refrigerant.R410A][0][0] ||
    psi > SaturationCurve[Refrigerant.R410A][SaturationCurve[Refrigerant.R410A].length - 1][0]
  ) {
    throw new Error(`psi ${psi} is beyond the curve`);
  }

  let i = 1;
  while (i < SaturationCurve[Refrigerant.R410A].length && SaturationCurve[Refrigerant.R410A][i][0] < psig) {
    i++;
  }
  // interpolate the temperature
  const [psig1, temp1] = SaturationCurve[Refrigerant.R410A][i - 1];
  const [psig2, temp2] = SaturationCurve[Refrigerant.R410A][i];
  return temp1 + ((temp2 - temp1) / (psig2 - psig1)) * (psig - psig1);
};

export const getPsiToF = (refrigerant: Refrigerant) => {
  switch (refrigerant) {
    case Refrigerant.R410A:
      return r410aPsiToF;
    default:
      throw new Error(`refrigerant ${refrigerant} not supported`);
  }
};
