import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import LayoutPayment from "../layout/LayoutPayment";
import { PaymentOption } from "./PaymentOption";
import { usePaymentStore } from "../payment-store";
import { useNavigate, useSearchParams } from "react-router-dom";

export const SubscriptionOptionsPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {} = usePaymentStore();
  const [searchParams] = useSearchParams();
  const deviceId = searchParams.get("deviceId");
  const { getPrices, prices, createPaymentIntent } = usePaymentStore((state) => state);
  const [highlightedIndex, setHighlightedIndex] = useState(1);

  useEffect(() => {
    if (!deviceId) {
      navigate("/dashboard");
      return;
    }
  }, [deviceId]);

  useEffect(() => {
    getPrices();
  }, []);

  const [montlyPrices, setMonthlyPrices] = useState([]);
  const [yearlyPrices, setYearlyPrices] = useState([]);

  useEffect(() => {
    console.log("[SubscriptionOptionPage] Prices loaded", prices);
    if (prices && prices.length > 0) {
      const monthly = prices.filter((price) => price.recurring.recurring.interval === "month");
      const yearly = prices.filter((price) => price.recurring.recurring.interval === "year");
      setMonthlyPrices(monthly);
      setYearlyPrices(yearly);
      console.log("[SubscriptionOptionPage] Monthly", monthly);
      console.log("[SubscriptionOptionPage] Yearly", yearly);
    }
  }, [prices]);

  return (
    <>
      <LayoutPayment>
        <div className="h-screen flex justify-center">
          <div className="container max-w-screen-xl px-8 items-center bg-transparent dark:bg-transparent">
            <div className="flex flex-col w-full justify-center mt-28 sm:mt-40 gap-8">
              <h2 className="font-semibold text-xl sm:text-3xl text-center">
                Select one of the available subscription options
              </h2>
            </div>
            <div className="h-full sm:px-4 py-6 mt-4 lg:px-8">
              <Tabs defaultValue="month" className="h-full space-y-6">
                <div className="space-between flex items-center justify-center">
                  <TabsList className="h-11 p-2 rounded-full space-x-2">
                    <TabsTrigger value="month" className="relative rounded-full">
                      Monthly
                    </TabsTrigger>
                    <TabsTrigger value="year" className="relative rounded-full">
                      Yearly
                    </TabsTrigger>
                  </TabsList>
                </div>
                <TabsContent
                  value="year"
                  className="flex-col border-none p-0 pt-6 outline-none data-[state=active]:flex"
                >
                  <div className="flex flex-col sm:flex-row justify-center gap-8">
                    {yearlyPrices.map((price, index) => (
                      <PaymentOption
                        key={index}
                        price={price}
                        isLoading={isLoading}
                        highlighted={highlightedIndex === index}
                        onMouseEnter={() => setHighlightedIndex(index)}
                        select={async (priceId) => {
                          setIsLoading(true);
                          await createPaymentIntent(deviceId, price.recurring.id, price.oneTime?.id);
                          navigate("/payment/checkout");
                          setIsLoading(false);
                        }}
                      />
                    ))}
                  </div>
                </TabsContent>
                <TabsContent value="month" className="flex-col border-none p-0 outline-none data-[state=active]:flex">
                  <div className="flex flex-col sm:flex-row justify-center gap-8 mt-4">
                    {montlyPrices.map((price, index) => (
                      <PaymentOption
                        key={index}
                        price={price}
                        isLoading={isLoading}
                        highlighted={highlightedIndex === index}
                        onMouseEnter={() => setHighlightedIndex(index)}
                        select={async (priceId) => {
                          setIsLoading(true);
                          await createPaymentIntent(deviceId, price.recurring.id, price.oneTime?.id);
                          navigate("/payment/checkout");
                          setIsLoading(false);
                        }}
                      />
                    ))}
                  </div>
                </TabsContent>
              </Tabs>
            </div>
          </div>
        </div>
      </LayoutPayment>
    </>
  );
};
