import { create, StoreApi, UseBoundStore } from "zustand";
import { Equipment, InstalledDevice, NewCustomerSetup } from "@rtbot-dev/json-schemas";
import { httpApi } from "@/http-api";

export type PartnerStoreState = {
  customerSetup: Omit<NewCustomerSetup, "monitoring"> | null;
  deviceSetup: Equipment | null;
  error: string | null;
  loading: boolean;
};

export type PartnerStoreActions = {
  setCustomerSetup: (customerSetup: Omit<NewCustomerSetup, "monitoring">) => void;
  setDeviceSetup: (customerSetup: Equipment) => void;
  initialSetup: () => Promise<void>;
  clearError: () => void;
};

export type PartnerStore = PartnerStoreState & PartnerStoreActions;

export const usePartnerStore: UseBoundStore<StoreApi<PartnerStore>> = create<PartnerStore>((set, get) => ({
  //Determine whether to place data in placeholder here or in the library, and whether the check is performed after the first change in each field
  customerSetup: null,
  loading: false,
  error: null,
  deviceSetup: null,
  clearError: () => {
    set(() => ({ error: null }));
  },
  setCustomerSetup: (customerSetup: Omit<NewCustomerSetup, "monitoring">) => {
    console.log("Customer setup", customerSetup);
    set(() => ({
      customerSetup,
    }));
  },
  setDeviceSetup: (deviceSetup: Equipment) => {
    set(() => ({ loading: false }));
    console.log("Device setup", deviceSetup);
    set(() => ({
      deviceSetup,
    }));
  },
  async initialSetup() {
    set(() => ({ loading: true }));
    console.log("Initial setup");
    // assembly data according to the schema expected by the backend
    const newCustomerSetup: NewCustomerSetup = {
      ...get().customerSetup!,
      monitoring: get().deviceSetup!,
    };

    // send the data to the backend
    console.log("Sending data to the backend", newCustomerSetup);
    try {
      const { data } = await httpApi.partnerStartSetup(newCustomerSetup);
      console.log("start setup response", data);
    } catch (e) {
      console.error("Error sending data to the backend", e);
      set(() => ({ error: e.message }));
      throw e;
    } finally {
      () => set(() => ({ loading: false }));
    }
  },
}));
