import React, { useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import TopBarCalculator from "./TopBarCalculator";
import { Button } from "@/components/ui/button";
import { Download, Info } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import jsPDF from "jspdf";

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const generatePdf = async (inputs, baseCosts, improvedCosts, improvements) => {
  // Create new PDF document
  const doc = new jsPDF();

  // Load and add logo with proper aspect ratio
  try {
    const logo = await loadImage("/logo-pdf.png");
    const maxWidth = 60; // Maximum width for logo
    const maxHeight = 20; // Maximum height for logo

    // Calculate dimensions maintaining aspect ratio
    let logoWidth = maxWidth;
    let logoHeight = (logo.height * maxWidth) / logo.width;

    // If height is too big, scale based on height instead
    if (logoHeight > maxHeight) {
      logoHeight = maxHeight;
      logoWidth = (logo.width * maxHeight) / logo.height;
    }

    // Center logo horizontally
    const pageWidth = doc.internal.pageSize.getWidth();
    const xPosition = (pageWidth - logoWidth) / 2;

    // Add logo
    doc.addImage("/logo-pdf.png", "PNG", xPosition, 15, logoWidth, logoHeight);

    // Adjust starting y position based on logo
    let y = 15 + logoHeight + 10;

    // Add title
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text("HVAC Fleet Cost Analysis Report", 15, y + 10);

    // Add generation date
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`Generated on ${new Date().toLocaleDateString()}`, 15, y + 20);

    y += 35;

    // Helper function to add a section with title
    const addSection = (title, content) => {
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text(title, 15, y);
      y += 5;
      doc.line(15, y, 195, y);
      y += 10;

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      content.forEach((line) => {
        if (y > 270) {
          doc.addPage();
          y = 20;
        }
        doc.text(line, 15, y);
        y += 6;
      });
      y += 5;
    };

    // Fleet Information Section
    const fleetInfo = [
      `Number of HVAC Units: ${inputs.numUnits}`,
      `Maintenance Visits/Year: ${inputs.maintenanceVisitsPerYear}`,
      `Cost per Scheduled Visit: $${inputs.scheduledVisitCost}`,
      `Emergency Calls/Year: ${inputs.emergencyCallsPerYear}`,
      `Cost per Emergency Call: $${inputs.emergencyCallCost}`,
      `Annual Parts Cost: $${inputs.annualPartsCost}`,
      `Replacement Cost/Unit: $${inputs.replacementCostPerUnit}`,
    ];
    addSection("Fleet Information", fleetInfo);

    // Current Costs Section
    const currentCosts = [
      `Scheduled Maintenance (${
        inputs.maintenanceVisitsPerYear
      }x/year): $${baseCosts.scheduledMaintenance.toLocaleString()}`,
      `Emergency Calls: $${baseCosts.emergency.toLocaleString()}`,
      `Parts: $${baseCosts.parts.toLocaleString()}`,
      `Unit Replacements (${
        Math.round(baseCosts.replacementsPerYear * 10) / 10
      }x/year): $${baseCosts.replacement.toLocaleString()}`,
      `Total Annual Cost: $${baseCosts.total.toLocaleString()}`,
    ];
    addSection("Current Annual Costs", currentCosts);

    // Improvements Section
    const improvementDetails = [
      `Emergency Call Reduction: ${improvements.emergencyCallReduction}%`,
      `Parts Cost Reduction: ${improvements.partsReduction}%`,
      `Lifespan Extension: ${improvements.lifespanExtensionPercent}%`,
      `Additional Maintenance Visits: ${improvements.additionalMaintenanceVisits}`,
    ];
    addSection("Cool Guardian Improvements", improvementDetails);

    // Projected Costs Section
    const projectedCosts = [
      `Scheduled Maintenance (${
        improvedCosts.maintenanceVisits
      }x/year): $${improvedCosts.scheduledMaintenance.toLocaleString()}`,
      `Emergency Calls: $${improvedCosts.emergency.toLocaleString()}`,
      `Parts: $${improvedCosts.parts.toLocaleString()}`,
      `Unit Replacements (${improvedCosts.replacementsPerYear.toFixed(
        1
      )} units/year): $${improvedCosts.replacement.toLocaleString()}`,
      `Cool Guardian Subscription ($${improvedCosts.monthlySubscriptionPerUnit.toFixed(
        1
      )}/unit/month): $${improvedCosts.subscription.toLocaleString()}`,
      `New Total Cost: $${improvedCosts.total.toLocaleString()}`,
    ];
    addSection("Projected Annual Costs with Cool Guardian", projectedCosts);

    // Total Savings Section
    const savings = baseCosts.total - improvedCosts.total;
    const savingsPercentage = ((savings / baseCosts.total) * 100).toFixed(1);

    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(39, 174, 96); // Green color for savings
    doc.text(`Total Annual Savings: $${savings.toLocaleString()} (${savingsPercentage}% reduction)`, 15, y + 10);
    doc.text(`Ready to save on your HVAC costs? Contact us at`, 15, y + 20);
    doc.setTextColor(0, 0, 255); // Blue color for email
    doc.text("services@katenaria.com", 15, y + 30);
  } catch (error) {
    console.error("Error loading logo:", error);
    // Continue generating PDF without logo if there's an error
  }
  return doc;
};

export const ManyUnitsCalculator = () => {
  const [searchParams] = useSearchParams();

  const subscriptionMonthlyCostParam = searchParams.get("subscriptionMontlyCost");

  const subscriptionMonthlyCost = subscriptionMonthlyCostParam ? parseFloat(subscriptionMonthlyCostParam) : 15;

  console.log("subscriptionMontlyCost", subscriptionMonthlyCost);

  const handleDownloadPdf = async () => {
    const doc = await generatePdf(inputs, baseCosts, improvedCosts, improvements);
    doc.save(`hvac-cost-analysis-${new Date().toISOString().split("T")[0]}.pdf`);
  };

  const [inputs, setInputs] = useState({
    numUnits: 100,
    maintenanceVisitsPerYear: 1,
    scheduledVisitCost: 150,
    emergencyCallsPerYear: 25,
    emergencyCallCost: 400,
    annualPartsCost: 15000,
    expectedLifespan: 12, // Base lifespan in years
    replacementCostPerUnit: 7000,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: parseFloat(value) || 0,
    }));
    // if we change the numUnits, then also update the base costs
    if (name === "numUnits") {
      const numUnits = parseFloat(value) || 0;
      setInputs((prev) => ({
        ...prev,
        annualPartsCost: numUnits * 150,
        emergencyCallsPerYear: numUnits / 4,
      }));
    }
  };

  const [improvements, setImprovements] = useState({
    emergencyCallReduction: 50,
    partsReduction: 30,
    lifespanExtensionPercent: 25,
    additionalMaintenanceVisits: 0,
  });

  const handleImprovementChange = (e) => {
    const { name, value } = e.target;
    setImprovements((prev) => ({
      ...prev,
      [name]: parseFloat(value) || 0,
    }));
  };

  // Calculate baseline replacements per year based on uniform distribution
  const calculateBaseReplacements = () => {
    return inputs.numUnits / inputs.expectedLifespan;
  };

  const calculateBaseCosts = () => {
    const baseReplacements = calculateBaseReplacements();
    const annualScheduledMaintenanceCost =
      inputs.numUnits * inputs.scheduledVisitCost * inputs.maintenanceVisitsPerYear;
    const annualEmergencyCost = inputs.emergencyCallsPerYear * inputs.emergencyCallCost;
    const annualReplacementCost = baseReplacements * inputs.replacementCostPerUnit;

    return {
      scheduledMaintenance: annualScheduledMaintenanceCost,
      emergency: annualEmergencyCost,
      parts: inputs.annualPartsCost,
      replacement: annualReplacementCost,
      replacementsPerYear: baseReplacements,
      total: annualScheduledMaintenanceCost + annualEmergencyCost + inputs.annualPartsCost + annualReplacementCost,
    };
  };

  const calculateImprovedCosts = (baseCosts) => {
    const totalMaintenanceVisits = inputs.maintenanceVisitsPerYear + improvements.additionalMaintenanceVisits;
    const improvedScheduledMaintenanceCost = inputs.numUnits * inputs.scheduledVisitCost * totalMaintenanceVisits;
    const improvedEmergencyCost = baseCosts.emergency * (1 - improvements.emergencyCallReduction / 100);
    const improvedPartsCost = baseCosts.parts * (1 - improvements.partsReduction / 100);

    // Calculate new replacement rate based on extended lifespan
    const extendedLifespan = inputs.expectedLifespan * (1 + improvements.lifespanExtensionPercent / 100);
    const improvedReplacementsPerYear = inputs.numUnits / extendedLifespan;
    const improvedReplacementCost = improvedReplacementsPerYear * inputs.replacementCostPerUnit;

    const baseSubscriptionCost = subscriptionMonthlyCost * 12 * inputs.numUnits;
    const annualDiscount = 0.1;
    let bulkDiscount = inputs.numUnits >= 80 ? 0.3 : 0;
    // we assume here that we want to control the subscription cost
    if (subscriptionMonthlyCostParam) {
      bulkDiscount = 0;
    }
    const subscriptionCost = baseSubscriptionCost * (1 - annualDiscount) * (1 - bulkDiscount);

    return {
      scheduledMaintenance: improvedScheduledMaintenanceCost,
      emergency: improvedEmergencyCost,
      parts: improvedPartsCost,
      replacement: improvedReplacementCost,
      subscription: subscriptionCost,
      monthlySubscriptionPerUnit: subscriptionMonthlyCost * (1 - annualDiscount) * (1 - bulkDiscount),
      replacementsPerYear: improvedReplacementsPerYear,
      total:
        improvedScheduledMaintenanceCost +
        improvedEmergencyCost +
        improvedPartsCost +
        improvedReplacementCost +
        subscriptionCost,
      maintenanceVisits: totalMaintenanceVisits,
    };
  };

  const baseCosts = calculateBaseCosts();
  const improvedCosts = calculateImprovedCosts(baseCosts);

  return (
    <TopBarCalculator>
      <div
        className="w-full mx-auto mt-6 h-96"
        style={{ background: 'url("/HVAC_fleet.jpg")', backgroundPositionY: "60%", backgroundSize: "cover" }}
      />
      <div className="text-center text-2xl font-semibold mt-8">Enterprise HVAC Cost Management Calculator</div>
      <div className="w-full max-w-3xl mx-auto mt-6">
        For property managers overseeing 100+ HVAC units, tracking maintenance costs and optimizing operations becomes
        increasingly complex.
        <br />
        <br />
        This calculator helps large-scale facility managers:
        <br />
        <br />
        <ul className="list-disc pl-6">
          <li>Calculate total costs across your entire HVAC fleet</li>
          <li>
            Break down expenses by scheduled maintenance ($150/visit), emergency repairs ($400/call), parts
            ($150-300/unit), and unit replacements ($7,000/unit)
          </li>
          <li>Project savings from implementing predictive maintenance solutions</li>
          <li>Plan budgets for properties with multiple HVAC systems</li>
        </ul>
        <br />
        Input your fleet size and maintenance parameters below to analyze your annual HVAC operations cost and explore
        potential optimizations.
      </div>
      <Card className="w-full max-w-3xl mx-auto mt-6">
        <CardContent className="pt-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="numUnits">Number of HVAC Units</Label>
              <Input type="number" name="numUnits" value={inputs.numUnits} onChange={handleInputChange} min="1" />
            </div>
            <div className="space-y-2">
              <Label htmlFor="maintenanceVisitsPerYear">Scheduled Maintenance Visits per Year</Label>
              <Input
                type="number"
                name="maintenanceVisitsPerYear"
                value={inputs.maintenanceVisitsPerYear}
                onChange={handleInputChange}
                min="0"
                step="0.5"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="scheduledVisitCost">Cost per Scheduled Visit ($)</Label>
              <Input
                type="number"
                name="scheduledVisitCost"
                value={inputs.scheduledVisitCost}
                onChange={handleInputChange}
                min="0"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="emergencyCallsPerYear">Emergency Calls per Year</Label>
              <Input
                type="number"
                name="emergencyCallsPerYear"
                value={inputs.emergencyCallsPerYear}
                onChange={handleInputChange}
                min="0"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="emergencyCallCost">Cost per Emergency Call ($)</Label>
              <Input
                type="number"
                name="emergencyCallCost"
                value={inputs.emergencyCallCost}
                onChange={handleInputChange}
                min="0"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="annualPartsCost">Total Annual Parts Cost ($)</Label>
              <Input
                type="number"
                name="annualPartsCost"
                value={inputs.annualPartsCost}
                onChange={handleInputChange}
                min="0"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="replacementCostPerUnit">Cost per Unit Replacement ($)</Label>
              <Input
                type="number"
                name="replacementCostPerUnit"
                value={inputs.replacementCostPerUnit}
                onChange={handleInputChange}
                min="0"
              />
            </div>
          </div>

          <div className="mt-6 p-4 bg-blue-50 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Current Annual Costs:</h3>
            <div className="grid grid-cols-2 gap-2">
              <div>Scheduled Maintenance ({inputs.maintenanceVisitsPerYear}x/year):</div>
              <div className="text-right">${baseCosts.scheduledMaintenance.toLocaleString()}</div>
              <div>Emergency Calls:</div>
              <div className="text-right">${baseCosts.emergency.toLocaleString()}</div>
              <div>Parts:</div>
              <div className="text-right">${baseCosts.parts.toLocaleString()}</div>
              <div>Unit Replacements (${Math.round(baseCosts.replacementsPerYear * 10) / 10}x/year):</div>
              <div className="text-right">${baseCosts.replacement.toLocaleString()}</div>
              <div className="font-bold">Total Annual Cost:</div>
              <div className="text-right font-bold">${baseCosts.total.toLocaleString()}</div>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className="w-full max-w-3xl mx-auto mt-6 p-6 bg-blue-50">
        <div className="flex items-start gap-2">
          <Info className="w-5 h-5 mt-1 text-blue-600" />
          <div>
            <h3 className="font-semibold text-blue-800">Replacement Rate Calculation</h3>
            <p className="text-sm text-blue-700">
              The calculator estimates annual replacements using a uniform distribution model. For example, with 100
              units lasting 12 years, approximately 8.33 units would need replacement each year (100/12). This
              simplified model assumes:
            </p>
            <ul className="list-disc pl-6 mt-2 text-sm text-blue-700">
              <li>Units fail uniformly over their lifespan</li>
              <li>The fleet age is evenly distributed</li>
              <li>Maintenance practices remain consistent</li>
            </ul>
            <p className="text-sm text-blue-700 mt-2">
              Actual replacement rates may vary based on usage patterns, environmental conditions, and specific
              maintenance practices.
            </p>
          </div>
        </div>
      </Card>
      <div className="text-center text-2xl font-semibold mt-8">Cool Guardian: Reducing Your Costs</div>
      <div className="w-full max-w-3xl mx-auto mt-6">
        Let's look at how Cool Guardian can help reduce your HVAC maintenance costs. The system works by monitoring your
        units to catch issues early, which typically leads to three main areas of savings: First, when problems are
        detected early, you'll have fewer emergency calls since maintenance can be scheduled before systems fail.
        Second, addressing wear and tear proactively means you'll spend less on replacement parts. Third, better
        maintenance often means fewer full unit replacements each year. Use the calculator below to see potential
        savings based on your specific situation - enter your expected reductions in emergency calls, parts expenses,
        and number of replacements to get a realistic picture of your annual savings with Cool Guardian.
      </div>
      <Card className="w-full max-w-3xl mx-auto mt-6">
        <CardContent className="pt-6 space-y-6">
          <div className="text-center font-semibold">Projected Improvements with Cool Guardian</div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="emergencyCallReduction">Emergency Call Reduction (%)</Label>
              <Input
                type="number"
                name="emergencyCallReduction"
                value={improvements.emergencyCallReduction}
                onChange={handleImprovementChange}
                min="0"
                max="100"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="partsReduction">Parts Cost Reduction (%)</Label>
              <Input
                type="number"
                name="partsReduction"
                value={improvements.partsReduction}
                onChange={handleImprovementChange}
                min="0"
                max="100"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="lifespanExtensionPercent">Lifespan Extension (%)</Label>
              <Input
                type="number"
                name="lifespanExtensionPercent"
                value={improvements.lifespanExtensionPercent}
                onChange={handleImprovementChange}
                min="0"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="additionalMaintenanceVisits">Additional Maintenance Visits per Year</Label>
              <Input
                type="number"
                name="additionalMaintenanceVisits"
                value={improvements.additionalMaintenanceVisits}
                onChange={handleImprovementChange}
                min="0"
                step="0.5"
              />
            </div>
          </div>

          <div className="p-4 bg-blue-50 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">Projected Annual Costs with Cool Guardian:</h3>
            <div className="grid grid-cols-3 gap-2">
              <div className="col-span-2">Scheduled Maintenance ({improvedCosts.maintenanceVisits}x/year):</div>
              <div className="text-right flex justify-end items-center space-x-2">
                <span>${improvedCosts.scheduledMaintenance.toLocaleString()}</span>
                {improvedCosts.scheduledMaintenance !== baseCosts.scheduledMaintenance && (
                  <span
                    className={
                      improvedCosts.scheduledMaintenance < baseCosts.scheduledMaintenance
                        ? "text-green-600 text-sm"
                        : "text-red-600 text-sm"
                    }
                  >
                    ({improvedCosts.scheduledMaintenance < baseCosts.scheduledMaintenance ? "-" : "+"}$
                    {Math.abs(baseCosts.scheduledMaintenance - improvedCosts.scheduledMaintenance).toLocaleString()})
                  </span>
                )}
              </div>
              <div className="col-span-2">Emergency Calls:</div>
              <div className="text-right flex justify-end items-center space-x-2">
                <span>${improvedCosts.emergency.toLocaleString()}</span>
                <span className="text-green-600 text-sm">
                  (-${(baseCosts.emergency - improvedCosts.emergency).toLocaleString()})
                </span>
              </div>
              <div className="col-span-2">Parts:</div>
              <div className="text-right flex justify-end items-center space-x-2">
                <span>${improvedCosts.parts.toLocaleString()}</span>
                <span className="text-green-600 text-sm">
                  (-${(baseCosts.parts - improvedCosts.parts).toLocaleString()})
                </span>
              </div>
              <div className="col-span-2">
                Unit Replacements ({improvedCosts.replacementsPerYear.toFixed(1)} units/year):
              </div>
              <div className="text-right flex justify-end items-center space-x-2">
                <span>${improvedCosts.replacement.toLocaleString()}</span>
                <span className="text-green-600 text-sm">
                  (-${(baseCosts.replacement - improvedCosts.replacement).toLocaleString()})
                </span>
              </div>
              <div className="col-span-2">
                Cool Guardian Subscription (${improvedCosts.monthlySubscriptionPerUnit.toFixed(1)} units/month):
              </div>
              <div className="text-right">${improvedCosts.subscription.toLocaleString()}</div>
              <div className="font-bold col-span-2">New Total Cost:</div>
              <div className="text-right font-bold">${improvedCosts.total.toLocaleString()}</div>
            </div>
          </div>

          <div className="p-4 bg-green-50 rounded-lg">
            <h3 className="font-semibold text-green-800">Total Annual Savings</h3>
            <div className="text-2xl font-bold text-green-700">
              ${(baseCosts.total - improvedCosts.total).toLocaleString()}
              <span className="text-lg ml-2">
                ({(((baseCosts.total - improvedCosts.total) / baseCosts.total) * 100).toFixed(1)}% reduction)
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="w-full max-w-3xl mx-auto mt-6 flex justify-center gap-3">
        <Button variant="outline" onClick={handleDownloadPdf} className="flex items-center gap-2">
          <Download className="w-4 h-4" /> Download Report
        </Button>
        <Button
          className=""
          onClick={() => {
            // open email link
            window.location.href = "mailto:services@katenaria.com";
          }}
        >
          Talk To Us
        </Button>
      </div>
    </TopBarCalculator>
  );
};
