import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AlertTriangle, CheckCircle2 } from "lucide-react";

export const SystemStatusCard = ({ device }) => (
  <Card
    className={`border-2 ${
      device.active ? "border-cyan-vivid/40 bg-cyan-vivid/5 shadow-lg" : "border-yellow-500 bg-yellow-500/10"
    }`}
  >
    <CardHeader>
      <CardTitle>System Status</CardTitle>
    </CardHeader>
    <CardContent>
      {device.active ? (
        <div className="flex items-center">
          <CheckCircle2 className="w-6 h-6 mr-2" />
          <span>System is operating normally.</span>
        </div>
      ) : (
        <div className="flex items-center">
          <AlertTriangle className="w-6 h-6 mr-2" />
          <span>System is currently offline.</span>
        </div>
      )}
    </CardContent>
  </Card>
);
