import { EquipmentSchema } from "@rtbot-dev/json-schemas";
import { useEffect, useState } from "react";
import { ShadcnJSONForms } from "@rtbot-dev/jsonforms-shadcn";
import { useStepper } from "@/components/ui/stepper";
import { Button } from "@/components/ui/button";
import { usePartnerStore } from "../../partner-store";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import { ToastAction } from "@/components/ui/toast";
import { Icons } from "@/components/ui/icons";

const schema = EquipmentSchema;

const uiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Label",
      text: "Equipment Information",
    },
    {
      type: "HorizontalLayout",
      elements: [
        { type: "Control", scope: "#/properties/refrigerant" },
        { type: "Control", scope: "#/properties/voltage" },
        { type: "Control", scope: "#/properties/meteringDevice" },
        { type: "Control", scope: "#/properties/heatPump" },
        { type: "Control", scope: "#/properties/efficiency" },
        { type: "Control", scope: "#/properties/packagedUnit" },
        { type: "Control", scope: "#/properties/brand" },
        { type: "Control", scope: "#/properties/tonnage" },
        { type: "Control", scope: "#/properties/wattage" },
      ],
    },
  ],
};

export const NewEquipmentRegistrationForm = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { deviceSetup, setDeviceSetup, initialSetup, error, clearError, loading } = usePartnerStore((state) => state);
  const [valid, setValid] = useState(false);
  const { prevStep, nextStep } = useStepper();

  useEffect(() => {
    console.log("Error from Initial Setup", error);
    if (error) {
      toast({
        variant: "destructive",
        title: "Error!",
        description: `Unfortunately, the data will not be saved correctly, caused by ${error}`,
        className: "flex flex-inline py-3",
        action: (
          <ToastAction
            className="flex px-3 my-3 border-2 rounded-lg text-sm text-center"
            altText="Retry"
            onClick={() => {
              clearError();
              sendDatatoBackend();
            }}
          >
            Retry
          </ToastAction>
        ),
      });
    }
  }, [error]);

  const sendDatatoBackend = async () => {
    try {
      await initialSetup();
      nextStep();
    } catch (e) {
      console.log("Error outside from store:", e.message);
    }
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full p-8 sm:max-w-xl">
          <ShadcnJSONForms
            schema={schema}
            uischema={uiSchema}
            data={deviceSetup}
            onChange={(e: any) => {
              // if there are no errors, update the local data source
              console.log("Data source updated", e);
              if (!e.errors || (e.errors.length === 0 && Object.keys(e.data).length > 0)) {
                console.log("no errors");
                setDeviceSetup(e.data);
                setValid(true);
              } else {
                console.log("errors");
                setValid(false);
              }
            }}
          />
          <div className="flex flex-col w-full gap-2 mt-8">
            <Button type="button" disabled={!valid || loading} onClick={sendDatatoBackend} size="sm" variant="default">
              {loading ? <Icons.spinner className="mr-2 h-4 w-4 animate-spin" /> : ""}
              Submit
            </Button>
            <div className="relative py-4">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-b border-gray-300"></div>
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-4 text-sm text-gray-500">or</span>
              </div>
            </div>
            <Button onClick={prevStep} size="sm" variant="secondary">
              Back
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
